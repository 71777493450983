
import { Component, Mixins } from "vue-property-decorator";
import { Doughnut, mixins } from "vue-chartjs";

@Component({
  name: "doughnut-chart",
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
})
export default class DoughnutChart extends Mixins(
  mixins.reactiveProp,
  Doughnut,
) {
  mounted() {
    this.renderChart(this.chartData, {
      responsive: true,
      maintainAspectRatio: true,
      rotation: 0.1 * Math.PI,
      cutoutPercentage: 70,
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    });
  }
}
