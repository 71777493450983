import store from "@/modules/store/";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      pageId: "",
    };
  },
  computed: {
    ...mapGetters({ household: "household/household" }),
  },
  methods: {
    setCurrentPageValid(isValid) {
      store.commit("system/setCurrentPageValid", isValid, { root: true });
    },
    async ["onFormValidated"](_prop, isValid = false) {
      this.setCurrentPageValid(isValid);

      if (isValid) {
        store.commit("household/update", this.household);
      }
    },

    async ["validateForm"](isValid = false) {
      try {
        isValid = await this.$refs.ruleForm.validate();
      } catch {
        isValid = false;
      }

      this.setCurrentPageValid(isValid);
    },
    updateHousehold() {
      store.commit("household/update", this.household);
    },
    resetScrollPosition() {
      document.documentElement.scrollTo({ top: 0 });
    },
  },
  inject: ["EventBus"],
  mounted() {
    this.resetScrollPosition();
    this.$nextTick(this.validateForm);
  },
};
