/* eslint-disable @typescript-eslint/no-unused-vars */
import i18n from "../../../../../../core/i18n/";

const LABEL_KEY = "pages.dream_home.filters.bedrooms.options.bedroom_count";

const MIN = 1;
const MAX = 5;
const STEP = 1;

function generateRange(from, to, step) {
  return [...Array(Math.floor((to - from) / step) + 1)].map(
    (_, i) => from + i * step,
  );
}

function buildLabel(amount) {
  return i18n.tc(LABEL_KEY, amount, {
    count: amount,
  });
}

function optionFactory(amount) {
  return {
    value: amount,
    label: amount,
  };
}

function generateBedroomsAmountOptions() {
  const availableOptions = generateRange(MIN, MAX, STEP);

  return availableOptions.map((value) => optionFactory(value));
}
const bedroomsAmountOptions = generateBedroomsAmountOptions();
export { bedroomsAmountOptions };
