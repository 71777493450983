/* eslint-disable  */
import PAGES from "@/modules/app/config/pages";
import {
  getPage,
  canTransitionToPage,
  continueTransitionToPage,
} from "@/modules/app/navigation/";
import NotificationMessage from "./factories/";

const initialState = {
  page: {
    currentPage: PAGES.PAGE_HOME_STATUS,
    currentPageValid: true,
  },
  map: {
    currentZoom: null,
  },
  notifications: [],
};

export default {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    setCurrentPage(state, pageId) {
      state.page.currentPage = pageId;
    },
    setCurrentPageValid(state, valid = false) {
      state.page.currentPageValid = valid;
    },
    setCurrentMapZoom(state, level) {
      state.map.currentZoom = level;
    },
    addNotification(state, { message, type, html }) {
      state.notifications = [new NotificationMessage(message, type, html)];
    },
    removeNotification(state, { id }) {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== id,
      );
    },
  },
  actions: {
    async ["goToPage"]({}, { page = "next" }) {
      const destinationPage = getPage(page);

      if (canTransitionToPage(destinationPage)) {
        continueTransitionToPage(destinationPage);
      }
    },
  },
  getters: {
    currentPage: (state) => {
      return state.page.currentPage;
    },
    currentPageValid: (state) => {
      return state.page.currentPageValid;
    },
    currentMapZoom: (state) => {
      return state.map.currentZoom;
    },
    notifications: (state) => {
      return state.notifications;
    },
  },
};
