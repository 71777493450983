<template>
  <a-row>
    <a-col :sm="12" :lg="6">
      <div class="question" :class="sassStyles.question">
        <h2 class="questionTitle" :class="sassStyles.questionTitle">
          {{ $t("pages.home_status.partner_title") }}
        </h2>
        <a-radio-group
          buttonStyle="solid"
          optionType="button"
          v-model="isWithPartner"
          @change="updateHousehold"
          class="partner-options"
        >
          <select-button :value="0">{{
            $t("pages.home_status.partner_options.alone")
          }}</select-button>
          <select-button :value="1" data-cy="household-member-switch-partner">{{
            $t("pages.home_status.partner_options.with_partner")
          }}</select-button>
        </a-radio-group>
      </div>

      <div class="question" :class="sassStyles.question">
        <h2 class="questionTitle" :class="sassStyles.questionTitle">
          {{ $t("pages.home_status.saving_journey_title") }}
        </h2>
        <a-radio-group
          buttonStyle="solid"
          optionType="button"
          v-model="household.savingJourneyOption"
          @change="savingJourneyChanged"
        >
          <select-button :value="1">
            {{ household.consideringHomeLabel }}
          </select-button>
          <select-button :value="2">
            {{ household.savingForFirstHomeLabel }}
          </select-button>
          <select-button :value="3">
            {{ household.switchingHomeLabel }}
          </select-button>
          <select-button :value="4">
            {{ household.additionalHomeLabel }}
          </select-button>
        </a-radio-group>
      </div>
    </a-col>

    <mq-layout mq="xl+">
      <div :class="sassStyles.mainPicture">
        <img src="img/step01-home-status.svg" />
      </div>
    </mq-layout>
  </a-row>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";

import sassStyles from "./pages.module.scss";
import SelectButton from "@/components/select-button/select-button.vue";
import PageMixin from "@/pages/mixins";
import PAGES from "@/modules/app/config/pages";

@Component({
  name: "home-status-page",
  components: { SelectButton },
  data() {
    return {
      sassStyles,
    };
  },
  mixins: [PageMixin],
})
export default class HomeStatusPage extends Vue {
  pageId = PAGES.PAGE_HOME_STATUS;

  get isWithPartner() {
    return Number(this.household.withPartner);
  }

  set isWithPartner(value) {
    this.household.withPartner = Boolean(value);
  }

  savingJourneyChanged() {
    this.validateForm();
  }

  validateForm() {
    this.setCurrentPageValid(this.household.savingJourneyOption > 0);
  }
}
</script>

<style lang="scss" scoped>
.question:first-of-type {
  @media (min-width: $mq-xl) {
    margin-bottom: 52px;
  }
}

.question:nth-of-type(2) {
  .questionTitle {
    margin-bottom: 20px;

    @media (min-width: $mq-xl) {
      margin-bottom: 30px;
    }
  }
}

.questionTitle {
  @media (min-width: $mq-xl) {
    margin-bottom: 30px;
  }
}

.ant-radio-group {
  width: 100%;
}

.partner-options {
  display: flex;

  .radio-item:first-of-type {
    margin-right: 10px;

    @media (min-width: $mq-xl) {
      margin-right: 16px;
    }
  }
}
</style>
