const DEAFULT_PAYLOAD = {
  expectedMoney: 0,
  moveInMonths: 0,
  savings: 0,
  monthlyContribution: 0,
  yearlyEarnings: 0,
  hasPartner: false,
  houseRegion: "England",
  firstTimeBuyerState: "",
};

const PARTNER_PAYLOAD = {
  partnerSavings: 0,
  partnerMonthlyContribution: 0,
  partnerYearlyEarnings: 0,
  partnerExpectedMoney: 0,
};

const buildPayload = (household) => {
  const owner = Object.assign({}, DEAFULT_PAYLOAD, {
    expectedMoney: household.owner.additionalMoney,
    moveInMonths: household.desiredTimeframe,
    savings: household.owner.currentSavings,
    monthlyContribution: household.owner.monthlySavings,
    yearlyEarnings: household.owner.yearlyIncome,
    hasPartner: household.withPartner,
    houseRegion: household.houseRegion,
    firstTimeBuyerState: household.firstTimeBuyerState,
  });

  const partner = household.withPartner
    ? Object.assign({}, PARTNER_PAYLOAD, {
        partnerSavings: household.partner.currentSavings,
        partnerMonthlyContribution: household.partner.monthlySavings,
        partnerYearlyEarnings: household.partner.yearlyIncome,
        partnerExpectedMoney: household.partner.additionalMoney,
      })
    : {};

  return {
    owner,
    partner,
  };
};

export default buildPayload;
