<template functional>
  <div class="app-loading-indicator">
    <svg :width="props.size" :height="props.size">
      <use xlink:href="img/spinner.svg#spinner"></use>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 50,
    },
  },
};
</script>
