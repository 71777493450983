
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "app-layout",
})
export default class AppLayout extends Vue {
  defaultLayout = "base";
  dreamHomeLayout = "dream-home";

  compactOrWide() {
    return ["xs", "sm", "md", "lg", "xl"].includes(this.$mq)
      ? "compact"
      : "wide";
  }

  dreamHomeLayoutMode() {
    if (this.compactOrWide() === "compact") {
      return "dream-home-compact";
    } else {
      return "dream-home-wide";
    }
  }

  get layout() {
    let layout;
    if (this.$route.meta?.layout === this.dreamHomeLayout) {
      layout = this.dreamHomeLayoutMode();
    } else {
      layout = this.$route.meta?.layout || this.defaultLayout;
    }
    return () => import(`@/layouts/${layout}/index.vue`);
  }
}
