<template>
  <filters-toggle data-cy="filters-toggle">
    <div class="filtersWrapper">
      <div class="bedroomsAmount">
        <label>{{
          $t("pages.dream_home.filters.bedrooms.bedrooms_label")
        }}</label>

        <multiple-select
          class="bedroomsAmountSelect"
          data-cy="bedrooms-amount-select"
          :options="bedroomsOptions"
          placeholderKey="pages.dream_home.filters.bedrooms.placeholder"
          @multiple-select-value-changed="bedroomAmountChanged"
          :value="bedroomsAmount"
        />
      </div>
      <div class="propertyType">
        <label>{{
          $t("pages.dream_home.filters.property_type.property_type_label")
        }}</label>
        <multiple-select
          class="propertyTypeSelect"
          data-cy="property-type-select"
          :options="propertyTypeOptions"
          placeholderKey="pages.dream_home.filters.property_type.placeholder"
          @multiple-select-value-changed="propertyTypeChanged"
          :value="propertyType"
        />
      </div>
    </div>
  </filters-toggle>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import FiltersToggle from "../filters-toggle/index.vue";
import MultipleSelect from "@/components/multiple-select/index.vue";
import { bedroomsAmountOptions } from "./helpers/bedrooms-amount-options";
import { propertyTypeOptions } from "./helpers/property-type-options";
import { DEFAULT_BEDROOMS_AMOUNT } from "@/modules/household/config";

@Component({
  components: { FiltersToggle, MultipleSelect },
  computed: {
    ...mapGetters({ household: "household/household" }),
  },
})
export default class extends Vue {
  bedroomsOptions = bedroomsAmountOptions;
  propertyTypeOptions = propertyTypeOptions;
  bedroomsAmount = DEFAULT_BEDROOMS_AMOUNT;
  propertyType = ["flat", "house"];

  mounted() {
    this.loadFiltersData();
  }

  bedroomAmountChanged(value) {
    const household = { ...this.household };
    household.bedroomsAmount = [...value];
    this.bedroomsAmount = [...value];

    this.$store.commit("household/update", household);
  }

  propertyTypeChanged(value) {
    const household = { ...this.household };
    household.propertyType = [...value];
    this.propertyType = [...value];

    this.$store.commit("household/update", household);
  }

  loadFiltersData() {
    this.propertyType = this.household.propertyType;
    this.bedroomsAmount = this.household.bedroomsAmount;
  }
}
</script>

<style lang="scss" scoped>
label {
  display: block;
  margin: 0;
  padding: 0 0 $space-size-05;
  color: $label-color;
}

.filtersWrapper {
  display: flex;
  flex-direction: column;

  @media (min-width: $mq-xl) {
    flex-direction: row;
  }
}

.bedroomsAmount,
.propertyType {
  flex-grow: 1;
}

.bedroomsAmount {
  margin-right: 0;

  @media (min-width: $mq-xl) {
    flex-direction: row;
    margin-right: $space-size-1;
  }
}

.bedroomsAmountSelect,
.propertyTypeSelect {
  width: 100%;
}
</style>
