
import { Component } from "vue-property-decorator";
import { TimeframeOption } from "./types";
import AppSearch from "../search/index.vue";
import { desiredTimeframesOptions } from "./helpers/desired-timeframes-options";

@Component({
  name: "desired-timeframe",
  props: {
    options: {
      type: Array as () => Array<TimeframeOption>,
      default: () => desiredTimeframesOptions,
    },
    value: {
      type: Number,
    },
    defaultValue: {
      type: Number,
    },
  },
})
export default class extends AppSearch {}
