
import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { periodInWords } from "@/modules/utils/date_helpers";
import { currencyFormatter } from "@/modules/utils/formatter";

@Component({
  name: "overachiever-modal",
  computed: {
    ...mapGetters({
      household: "household/household",
      increaseMonthlyContribution: "targetPlan/increaseMonthlyContribution",
      increaseTime: "targetPlan/increaseTime",
    }),
  },
})
export default class extends Vue {
  value = null;
  increaseMonthlyContribution: any;
  increaseTime: any;
  household: any;

  mounted() {
    this.updateStretchType("");
  }

  get increaseSavingsDescription() {
    return this.$t(
      `pages.dream_home.overachiever_modal.increase_savings.description`,
      {
        formattedAmount: currencyFormatter(this.increaseMonthlyContribution, {
          round: false,
        }),
        period: periodInWords(this.household.desiredTimeframe),
      },
    );
  }

  get moveInLaterDescription() {
    return this.$t(
      `pages.dream_home.overachiever_modal.move_in_later.description`,
      {
        amount: this.household.calculatedSavings,
        period: periodInWords(this.increaseTime),
      },
    );
  }

  submit() {
    this.$store.dispatch("targetPlan/calculateStretchPlan", this.value);
    this.$store.dispatch("system/goToPage", { page: "next" });
  }

  cancel() {
    this.$store.commit("household/clearPropertyId", {
      root: true,
    });
    this.updateStretchType("");
    this.$emit("close");
  }

  updateStretchType(value) {
    this.$store.commit("targetPlan/updateStretchPlan", { stretchedBy: value });
  }
}
