<template>
  <main
    :class="[sassStyles.root, { [sassStyles.open]: open }]"
    data-cy="monthly-savings-select"
  >
    <label :class="sassStyles.label" class="ant-form-item-label">{{
      $t("pages.dream_home.filters.total_monthly_savings.label")
    }}</label>
    <div :class="sassStyles.select" @click.capture="selectToggled">
      <span :class="sassStyles.total">{{ total }}</span>
      <img src="img/arrow-up-icon.svg" :class="sassStyles.expandIcon" />
    </div>

    <div v-if="open" :class="sassStyles.menu">
      <a-form-model-item
        :label="
          $t(
            'pages.dream_home.filters.total_monthly_savings.owner_savings_label',
          )
        "
      >
        <a-input-number
          v-model="ownerSavings"
          :min="0"
          :formatter="formatCurrency"
          :parser="parseCurrency"
          @change="(value) => savingsUpdated('owner', value)"
          @pressEnter="close"
          data-cy="monthly-savings-select-owner"
        />
      </a-form-model-item>

      <a-form-model-item
        :label="
          $t(
            'pages.dream_home.filters.total_monthly_savings.partner_savings_label',
          )
        "
        v-if="householdWithPartner"
      >
        <a-input-number
          v-model="partnerSavings"
          :min="0"
          :formatter="formatCurrency"
          :parser="parseCurrency"
          @change="(value) => savingsUpdated('partner', value)"
          @pressEnter="close"
          data-cy="monthly-savings-select-partner"
        />
      </a-form-model-item>
    </div>
  </main>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import FormMixin from "@/components/form/mixins/";
import sassStyles from "./monthly-savings-select.module.scss";
import { currencyFormatter } from "@/modules/utils/formatter";

@Component({
  name: "monthly-savings-select",
  inject: ["EventBus"],
  data() {
    return {
      sassStyles,
    };
  },
  computed: {
    ...mapGetters({
      household: "household/household",
      householdWithPartner: "household/withPartner",
      totalMonthlySavings: "household/totalMonthlySavings",
    }),
  },
  mixins: [FormMixin],
})
export default class extends Vue {
  open = false;
  ownerSavings = 0;
  partnerSavings = 0;

  mounted() {
    this.loadData();
    document.addEventListener("click", this.detectOutsideClick);
  }

  beforeDestroy() {
    document.removeEventListener("click", this.detectOutsideClick);
  }

  get total() {
    return currencyFormatter(this.totalMonthlySavings, {
      round: false,
      symbolWithSpace: true,
    });
  }

  detectOutsideClick(e) {
    if (!this.$el.contains(e.target)) {
      this.close();
    }
  }

  savingsUpdated(member = "owner", value) {
    const eventName =
      member === "owner"
        ? "ownerMonthlySavingsChanged"
        : "partnerMonthlySavingsChanged";
    this.EventBus.$emit[eventName](value);
  }

  loadData() {
    this.ownerSavings = this.household.owner.monthlySavings;
    this.partnerSavings = this.household.partner.monthlySavings;
  }

  selectToggled() {
    this.open = !this.open;

    if (this.open) {
      this.loadData();
    }
  }

  close() {
    this.open = false;
  }
}
</script>
