import i18n from "../../../core/i18n";

export const dateInMonths = (numberOfMonths = 1) => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + numberOfMonths, 1);
};

export const periodInWords = (numberOfMonths = 1) => {
  const months = numberOfMonths % 12;
  const years = Math.floor(numberOfMonths / 12);
  const yearsLabel = i18n.tc("years_count", years, {
    count: years,
  });
  const monthsLabel = i18n.tc("months_count", months, {
    count: months,
  });

  if (months === 0) {
    return yearsLabel;
  } else if (years === 0) {
    return monthsLabel;
  } else {
    return `${yearsLabel} ${i18n.tc("and")} ${monthsLabel}`;
  }
};

export const dateShortFormat = (datetime) => {
  const date = new Date(datetime);
  const year = date.getFullYear();
  const monthName = new Intl.DateTimeFormat("en-GB", { month: "short" }).format(
    date,
  );

  return `${monthName} ${year}`;
};
