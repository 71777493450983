<template>
  <div class="notification" :class="`notification--${notification.type}`">
    <span class="notification-icon">
      <img :src="iconPath" />
    </span>

    <div class="notification-content">
      <span v-if="!notification.html">{{ notification.message }}</span>
      <span else v-html="notification.message"></span>
    </div>

    <button
      class="close-button"
      @click="$emit('notification-closed', notification)"
    >
      <img class="svg" src="img/close.svg" />
    </button>
  </div>
</template>

<script>
import { Vue, Component, Prop } from "vue-property-decorator";
import iconSuccess from "./ui/check-icon.svg";
import iconWarning from "./ui/warning-icon.svg";

@Component({
  name: "notification",
})
export default class extends Vue {
  @Prop({ required: true })
  notification;

  get iconPath() {
    return this.notification.type === "success" ? iconSuccess : iconWarning;
  }
}
</script>

<style lang="scss" scoped>
.notification {
  position: relative;
  padding: 20px 50px;
  background-color: $color-base-white;
  border: 1px solid $color-base-gray-100;
  border-radius: 3px;
  color: $color-base-gray-500;
  font-weight: $font-weight-bold;

  @media (min-width: $mq-xl) {
    font-weight: $font-weight-regular;
    padding: 12px 50px;
  }

  &--success {
    border-color: $color-base-green-200;
    background-color: #ccffe7;
  }

  &--error {
    border-color: $color-base-yellow-200;
    background-color: $color-base-yellow-100;
  }

  &-icon {
    position: absolute;
    top: 20px;
    left: 12px;
    width: 24px;

    @media (min-width: $mq-xl) {
      top: 10px;
      left: 10px;
      width: 30px;
    }
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    border: 0;
    padding: 0;
    background: none;
    cursor: pointer;

    @media (min-width: $mq-xl) {
      top: 10px;
      right: 10px;
    }
  }
}
</style>
