
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  name: "calculated-affordability",
})
export default class extends Vue {
  @Prop({ type: String })
  amount!: string;
}
