// TODO: Move common validation rules definition from other pages here
import i18n from "../../core/i18n";

const onlyNumbersAllowed = i18n.tc("feedback.savings.only_numbers_allowed");

export const onlyNumbersRule = {
  required: true,
  message: onlyNumbersAllowed,
  trigger: "blur",
};

export const requiredRule = {
  required: false,
  trigger: "blur",
};
