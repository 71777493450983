import i18n from "../../../../core/i18n";

import { isPasswordStrong } from "@/modules/utils/password-strength";

const passwordsNotEqual = i18n.tc("feedback.password_not_equal");

function validatePassword(_rule, value, callback) {
  if (isPasswordStrong(value)) {
    callback();
  } else {
    callback(new Error());
  }
}

function validateEquality(_rule, value, callback) {
  if (value === this.form.newPassword) {
    callback();
  } else {
    callback(new Error(passwordsNotEqual));
  }
}

export { validatePassword, validateEquality };
