
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import sassStyles from "./full-page.module.scss";

type PageEvent = "page-opened" | "page-closed";

@Component({
  name: "full-page",
  inject: ["EventBus"],
  data() {
    return {
      sassStyles,
    };
  },
})
export default class extends Vue {
  @Prop({ type: String, default: "page-full-page", required: false })
  pageId!: string;
  @Prop({ type: String, default: "title" }) pageTitle!: string;

  PAGE_OPENED = "page-opened";
  PAGE_CLOSED = "page-closed";

  isOpen = false;

  @Watch("isOpen")
  updateElevation(open) {
    // Watcher: targeting :global(#navigation-panel) doesn't work
    const initialZIndex = 1001;
    const lowZIndex = 0;
    let zIndex = initialZIndex;

    const $navigationPanel = document.getElementById(
      "navigation-panel",
    ) as HTMLElement;
    zIndex = open ? lowZIndex : initialZIndex;

    $navigationPanel.style.zIndex = zIndex.toString();
  }

  open(): void {
    this.isOpen = true;
    this.setRootScrollVisibility();
  }

  close(): void {
    this.isOpen = false;
    this.setRootScrollVisibility(true);
  }

  setRootScrollVisibility(show = false): void {
    const value = show ? "visible" : "hidden";
    document.getElementsByTagName("html")[0].style.overflow = value;
  }

  emitEvent(pageEvent: PageEvent): void {
    try {
      if (!pageEvent) {
        throw new Error("pageEvent name is required");
      }

      this.$emit(pageEvent, { pageId: this.pageId });
    } catch (e) {
      throw new Error(e.message);
    }
  }

  fullPageOpenedListener() {
    this.EventBus.$on.fullPageOpened((pageId) => {
      if (this.pageId === pageId) {
        this.open();
        this.emitEvent(this.PAGE_OPENED as PageEvent);
      }
    });
  }

  fullPageClosedListener() {
    this.EventBus.$on.fullPageClosed((pageId) => {
      if (this.pageId === pageId) {
        this.close();
        this.emitEvent(this.PAGE_CLOSED as PageEvent);
      }
    });
  }

  mounted() {
    this.fullPageOpenedListener();
    this.fullPageClosedListener();
  }
}
