
import { Component, Vue } from "vue-property-decorator";
import FiltersWide from "./filters-wide.vue";
import FiltersCompact from "./filters-compact.vue";

@Component({
  name: "dream-home-filters",
  components: { FiltersWide, FiltersCompact },
})
export default class extends Vue {}
