import i18n from "../../../../core/i18n";
import { TimeframeOption } from "../types";

const PERIOD = 12;
const MIN_MONTHS = 12;
const MAX_MONTHS = 20 * 12;
const STEP = 6;
const YEARS_COUNT_KEY = "years_count";
const MONTHS_COUNT_KEY = "months_count";

function generateRange(from, to, step) {
  return [...Array(Math.floor((to - from) / step) + 1)].map(
    (_, i) => from + i * step,
  );
}

function buildLabel(monthsCount) {
  const years = Math.floor(monthsCount / PERIOD);
  const months = monthsCount % PERIOD;

  const yearsLabel = i18n.tc(YEARS_COUNT_KEY, years, {
    count: years,
  });

  const monthsLabel = i18n.tc(MONTHS_COUNT_KEY, months, {
    count: months,
  });

  if (months === 0) {
    return yearsLabel;
  } else {
    return `${yearsLabel} ${monthsLabel}`;
  }
}

function timeframeOptionFactory(monthsCount): TimeframeOption {
  const label = buildLabel(monthsCount);

  return {
    value: monthsCount,
    label,
  };
}

function generateTimeframesOptions(): Array<TimeframeOption> {
  const shortRepaymentMonths = generateRange(MIN_MONTHS, 5 * PERIOD, STEP);
  const shortRepaymentOptions = shortRepaymentMonths.map((value) =>
    timeframeOptionFactory(value),
  );

  const lifeLongRepaymentMonths = generateRange(6 * PERIOD, MAX_MONTHS, PERIOD);
  const lifeLongRepaymentOptions = lifeLongRepaymentMonths.map((value) =>
    timeframeOptionFactory(value),
  );

  return [...shortRepaymentOptions, ...lifeLongRepaymentOptions];
}

const desiredTimeframesOptions = generateTimeframesOptions();

export { desiredTimeframesOptions };
