
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  name: "results-view-switch",
  computed: {
    ...mapGetters({
      propertyView: "mobile/propertyView",
    }),
  },
})
export default class extends Vue {
  @Prop({ required: false })
  options!: any;
  propertyView!: any;

  viewChanged(e) {
    this.$store.commit("mobile/setPropertyView", e.target.value);
  }
}
