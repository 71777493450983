import Property from "../model";
import store from "@/modules/store";

const affordabiltyCategoryFactory = () => ({
  properties: [],
  geoJson: {
    type: "FeatureCollection",
    features: [],
  },
});

const sortedPropertiesFactory = (name, properties, priority) => ({
  name,
  properties,
  count: properties.length,
  priority,
});

/**
 * Generate unique ID to match location select entry, property entry in the list, and pin on the map
 *
 * @param {string} tickerId
 * @param {integer} id
 */
const uniqId = (id) => {
  const prefix = "property";
  return `__${prefix}-${id}-${Math.floor(Math.random() * Date.now())}__`;
};

/**
 * Build GeoJson payload
 *
 * @param {string[]} outcodes
 */

const buildGeoJsonPayload = (outcodes) => {
  const { bedroomsAmount, propertyTypeEnum } =
    store.getters["household/household"];

  const { affordableHomePrice } = store.getters["targetPlan/targetPlan"];

  return {
    outcodes: outcodes,
    affordableHomePrice: affordableHomePrice,
    bedrooms: [...bedroomsAmount],
    propertyType: [...propertyTypeEnum],
  };
};

const addUniqIdToGeoJsonFeatures = (geoJson) => {
  const geoJsonCopy = { ...geoJson };
  const { features } = geoJsonCopy;
  features.forEach((f, idx) => (f.properties.id = uniqId(idx)));
  geoJsonCopy.features = features;
  return geoJsonCopy;
};

/**
 * Create Property instance out of geoJson feature object
 *
 * @param {obj} f
 * @param {idx} number
 */
const featureToProperty = (f) => {
  return new Property({
    id: f.properties.id,
    price: f.properties.price,
    propertyType: f.properties.propertyType,
    bedroomsAmount: f.properties.bedroomsAmount,
    location: f.properties.location,
    affordability: f.properties.affordability,
    coordinates: f.geometry.coordinates,
  });
};

const makeCategory = (collection) => {
  const obj = affordabiltyCategoryFactory();

  obj.geoJson.features = [...collection];
  obj.properties = collection.map((f) => featureToProperty(f));

  return obj;
};

/**
 * Turn GeoJson response into easy, affordable, and stretch collections
 *
 * @param {obj} data
 */
const categorizeProperties = (data) => {
  const easyCollection = data.features.filter(
    (f) => f.properties.affordability === 0,
  );
  const easy = makeCategory(easyCollection);

  const affordableCollection = data.features.filter(
    (f) => f.properties.affordability === 1,
  );
  const affordable = makeCategory(affordableCollection);

  const stretchCollection = data.features.filter(
    (f) => f.properties.affordability === 2,
  );
  const stretch = makeCategory(stretchCollection);

  return {
    easy,
    affordable,
    stretch,
  };
};

const sortPropertiesByPriority = ({
  easyProperties,
  affordableProperties,
  stretchProperties,
}) => {
  const list = [
    sortedPropertiesFactory("easy", easyProperties, 2),
    sortedPropertiesFactory("affordable", affordableProperties, 3),
    sortedPropertiesFactory("stretch", stretchProperties, 1),
  ];

  const sorted = list.sort((a, b) =>
    a.count > b.count
      ? 1
      : a.count === b.count
      ? a.priority > b.priority
        ? 1
        : -1
      : -1,
  );

  return sorted;
};

const scrollPropertyIntoView = (propertyId) => {
  if (!propertyId) {
    return;
  }

  const id = `${propertyId}-item`;
  document.getElementById(id).scrollIntoView({
    behavior: "smooth",
    block: "nearest",
  });
};

export {
  uniqId,
  featureToProperty,
  buildGeoJsonPayload,
  categorizeProperties,
  affordabiltyCategoryFactory,
  addUniqIdToGeoJsonFeatures,
  sortPropertiesByPriority,
  scrollPropertyIntoView,
};
