
import { Vue, Watch, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";

import { LMap, LTileLayer, LGeoJson } from "vue2-leaflet";
import config from "./config";
import configMobile from "./config-mobile";
import MapController from "./map-controller";
import sassStyles from "./app-map.module.scss";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type VMap = Vue & { mapObject: () => any };

@Component({
  name: "app-map",
  inject: ["EventBus"],
  data() {
    return {
      sassStyles,
    };
  },
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
  },
  computed: {
    ...mapGetters({
      properties: "property/properties",
      propertiesByPriority: "property/propertiesByPriority",
      propertiesByAffordability: "property/propertiesByAffordability",
      activePropertyCategory: "property/activePropertyCategory",
      layersVisibility: "property/layersVisibility",
      geoJsonEasy: "property/geoJsonEasy",
      geoJsonAffordable: "property/geoJsonAffordable",
      geoJsonStretch: "property/geoJsonStretch",
      geoJsonFeatures: "property/geoJsonFeatures",
      selectedPropertyId: "household/selectedPropertyId",
      currentMapZoom: "system/currentMapZoom",
    }),
  },
})
export default class extends Vue {
  loading = false;
  properties: any;
  propertiesByPriority: any;
  propertiesByAffordability: any;
  selectedPropertyId: any;
  activePropertyCategory: any;
  layersVisibility!: any;
  geoJson: any;
  geoJsonFeatures: any;
  mapController!: any;
  currentMapZoom!: any;

  created() {
    this.mapController = new MapController();
    this.EventBus.$on.rerenderMap(() => {
      this.mapController.rerenderMap();
    });
  }

  beforeDestroy() {
    this.mapController.destroy();
  }

  mounted() {
    this.$nextTick(() => {
      this.mapController.setMapObject((this.$refs.mapEl as VMap).mapObject);
      this.updateMapPosition();
      if (this.selectedProperty?.id) {
        this.highlightMarker();
      }
    });
  }

  get config() {
    if (["xs", "sm", "md", "lg", "xl"].includes(this.$mq)) {
      return configMobile;
    } else {
      return config;
    }
  }

  get selectedProperty() {
    return this.$store.getters["property/propertyById"](
      this.selectedPropertyId,
    );
  }

  get initialCenter() {
    return this.mapController.defaultMapLatLng;
  }

  get initialZoom() {
    return this.config.initialZoom;
  }

  get currentZoom() {
    return this.currentMapZoom || this.initialZoom;
  }

  set currentZoom(val) {
    this.$nextTick(() => this.$store.commit("system/setCurrentMapZoom", val));
  }

  _currentCenter!: null | number;
  get currentCenter() {
    return this._currentCenter || this.initialCenter;
  }

  set currentCenter(val) {
    this._currentCenter = val;
  }

  @Watch("selectedProperty.latLngBound")
  highlightMarker() {
    if (this.selectedProperty?.latLngBound) {
      this.mapController.highlightMarker(this.selectedProperty);
    }
  }

  get shouldUpdatePosition() {
    return this.geoJsonFeatures.length + this.activePropertyCategory;
  }

  @Watch("shouldUpdatePosition")
  updateMapPosition() {
    let zoom, center;

    if (!this.geoJsonFeatures.length) {
      zoom =
        this.currentZoom !== this.initialZoom
          ? this.currentZoom
          : this.initialZoom;
      center =
        this.currentCenter !== this.initialCenter
          ? this.currentCenter
          : this.initialCenter;
    } else if (
      this.propertiesByAffordability[this.activePropertyCategory].properties
        ?.length
    ) {
      zoom = this.currentZoom !== this.initialZoom ? this.currentZoom : 16;
      center =
        this.propertiesByAffordability[this.activePropertyCategory]
          .properties[0].latLngBound;
    } else {
      zoom = this.currentZoom;
      center = this.currentCenter;
    }

    this.currentZoom = zoom;
    this.currentCenter = center;

    this.mapController.setView(center, zoom);
  }

  zoomUpdated(zoom) {
    this.$nextTick(() => (this.currentZoom = zoom));
  }
}
