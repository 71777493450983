import L from "leaflet";
import { currencyFormatter } from "@/modules/utils/formatter";
import styles from "./map-marker.module.scss";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markerHtml = (price, elementId) => {
  return `<span id="${elementId}" class="${
    styles.root
  } mapMarker">${currencyFormatter(price, { short: true })}</span>`;
};

const DEFAULT_OPTIONS = {
  className: "map-marker",
  iconSize: [68, 40],
};

export default class MapMarker extends L.DivIcon {
  constructor(price = 0, _options) {
    const options = Object.assign({}, DEFAULT_OPTIONS, _options, {
      // eslint-disable-next-line no-undef
      html: markerHtml(price, _options.elementId),
    });

    super(options);
    this.price = price;
  }
}
