import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeStatusPage from "@/pages/home-status-page.vue";
import AffordabilityPage from "@/pages/affordability-page/index.vue";
import Affordability2Page from "@/pages/affordability-2-page/index.vue";
import DreamHomePage from "@/pages/dream-home-page/index.vue";
import TargetPlanPage from "@/pages/target-plan-page/index.vue";
import LoginPage from "@/auth/pages/login-page/index.vue";
import ForgotPasswordPage from "@/auth/pages/forgot-password/index.vue";
import InboxPage from "@/auth/pages/inbox-page/index.vue";
import ResetPasswordPage from "@/auth/pages/reset-password-page/index.vue";
import PAGES from "@/modules/app/config/pages";
import store from "@/modules/store/";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: PAGES.PAGE_HOME_STATUS_PATH,
    name: PAGES.PAGE_HOME_STATUS,
    component: HomeStatusPage,
  },
  {
    path: PAGES.PAGE_AFFORDABILITY_PATH,
    name: PAGES.PAGE_AFFORDABILITY,
    component: AffordabilityPage,
  },
  {
    path: PAGES.PAGE_AFFORDABILITY2_PATH,
    name: PAGES.PAGE_AFFORDABILITY2,
    component: Affordability2Page,
  },
  {
    path: PAGES.PAGE_DREAM_HOME_PATH,
    name: PAGES.PAGE_DREAM_HOME,
    component: DreamHomePage,
    meta: {
      layout: "dream-home",
    },
  },
  {
    path: PAGES.PAGE_TARGET_PLAN_PATH,
    name: PAGES.PAGE_TARGET_PLAN,
    component: TargetPlanPage,
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordPage,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/inbox",
    name: "inbox",
    component: InboxPage,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPasswordPage,
    meta: {
      layout: "auth",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
});

router.beforeEach((to, from, next) => {
  const pageName = to.name;

  if (PAGES.PAGES_LIST.some((p) => p === pageName)) {
    store.commit("system/setCurrentPage", pageName);
    next();
  } else {
    next();
  }
});

export default router;
