import FormSupport from "../support";
import { currencyFormatter } from "@/modules/utils/formatter.ts";

export default {
  methods: {
    formatCurrency(value) {
      return currencyFormatter(value, { round: false, symbolWithSpace: true });
    },
    parseCurrency(value) {
      return FormSupport.currencyParser(value);
    },
    formLabel(labelKey) {
      return FormSupport.label(labelKey);
    },
  },
};
