
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import Notification from "@/components/notification/index.vue";

@Component({
  components: {
    Notification,
  },
  computed: {
    ...mapGetters({
      notifications: "system/notifications",
    }),
  },
})
export default class extends Vue {
  removeNotification(notification) {
    this.$store.commit("system/removeNotification", notification, {
      root: true,
    });
  }
}
