import L from "leaflet";
import { latLng } from "leaflet";
import MapMarker from "./components/map-marker/";
import EventBus from "@/modules/app/communication/EventBus";
import store from "@/modules/store/";

const MARKER_CLASS = "mapMarker";
const ACTIVE_MARKER_CLASS = "activeMapMarker";

const getMarkerIcon = (elementId) => {
  return document.getElementById(elementId);
};

const removeActiveMarkerClass = () => {
  document.querySelectorAll(`.${MARKER_CLASS}`).forEach((el) => {
    el.classList.remove(ACTIVE_MARKER_CLASS);
  });
};

const _highlightMarker = (propertyId) => {
  removeActiveMarkerClass();
  const markerEl = getMarkerIcon(propertyId);
  if (!markerEl) {
    return;
  }
  markerEl.classList.add(ACTIVE_MARKER_CLASS);
};

const markerClicked = (e, feature) => {
  const property = store.getters["property/propertyById"](
    feature.properties.id,
  );
  _highlightMarker(property.id);

  store.commit("household/setPropertyId", property.id);
  EventBus.$emit.propertySelectedOnMap(property.id);
};

const processFeature = (feature, layer) => {
  layer.on({ click: (e) => markerClicked(e, feature) });
};

const renderMarker = (feature, latlng) => {
  const { price, id } = feature.properties;
  return L.marker(latlng, {
    icon: new MapMarker(price, { elementId: id }),
    riseOnHover: true,
  });
};

export default class {
  constructor() {
    this.map = null;
  }

  get easyLayerOptions() {
    return {
      name: "affordability-easy",
      pointToLayer: renderMarker,
      onEachFeature: processFeature,
    };
  }

  get affordableLayerOptions() {
    return {
      name: "affordability-affordable",
      pointToLayer: renderMarker,
      onEachFeature: processFeature,
    };
  }

  get stretchLayerOptions() {
    return {
      name: "affordability-stretch",
      pointToLayer: renderMarker,
      onEachFeature: processFeature,
    };
  }

  get defaultMapLatLng() {
    return latLng(52.896, -2.967);
  }

  setMapObject(mapObject) {
    this.map = mapObject;
  }

  highlightMarker(property) {
    _highlightMarker(property.id);
    this.setView(property.latLngBound);
  }

  setView(center, zoom) {
    this.map.setView(center, zoom);
  }

  rerenderMap() {
    this.map.invalidateSize();
  }

  destroy() {
    EventBus.$off.rerenderMap();
  }
}
