
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  name: "property-card",
})
export default class extends Vue {
  @Prop({ required: true })
  property!: any;

  @Prop({ type: Boolean, default: false })
  selected!: false;

  @Prop({ default: false })
  displayHandler!: boolean;

  selectOption(): void {
    this.$emit("property-selected", this.property.id);
  }
}
