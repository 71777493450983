
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  name: "app-accordion",
})
export default class extends Vue {
  @Prop({ type: String, required: false, default: "" })
  activePanel!: string;

  isActive = false;
}
