const initialState = {
  propertyView: "list",
};

export default {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    setPropertyView(state, name) {
      state.propertyView = name;
    },
  },
  getters: {
    propertyView: (state) => state.propertyView,
    showPropertyList: (state) => state.propertyView === "list",
    showMobileMap: (state) =>
      state.propertyView === "map-collapsed" ||
      state.propertyView === "map-expanded",
    mapCollapsed: (state) => state.propertyView === "map-collapsed",
    mapExpanded: (state) => state.propertyView === "map-expanded",
  },
};
