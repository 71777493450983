
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "select-button",
})
export default class SelectButton extends Vue {
  @Prop({ default: 0, type: Number })
  value!: number;
}
