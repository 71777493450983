
import { Vue, Component, Prop } from "vue-property-decorator";
import PropertyCard from "../../property-card/index.vue";

@Component({
  name: "cards-select",
  components: {
    PropertyCard,
  },
})
export default class CardsSelect extends Vue {
  @Prop({ type: Array, required: true })
  data!: any;

  @Prop({ type: String, required: false })
  selectedPropertyId!: string;

  get properties() {
    return this.data.map((p) => {
      const { id, propertyTypeName, bedroomsAmount, location, formattedPrice } =
        p;
      return {
        id,
        propertyTypeName,
        bedroomsAmount,
        location,
        formattedPrice,
      };
    });
  }

  setResult(propertyId) {
    this.$emit("set-result", propertyId);
  }

  checkSelected(propertyId): boolean {
    return this.selectedPropertyId === propertyId;
  }
}
