/* eslint-disable @typescript-eslint/camelcase */
const VUE_APP_AUTH_CLIENT_ID = process.env.VUE_APP_AUTH_CLIENT_ID.toString();
const VUE_APP_AUTH_CONNECTION_DB = process.env.VUE_APP_AUTH_CONNECTION_DB.toString();
const buildPayload = (data) => {
  return {
    email: data.email,
    password: data.password,
    user_metadata: {
      name: data.username,
      telephone: "",
    },
    client_id: VUE_APP_AUTH_CLIENT_ID,
    connection: VUE_APP_AUTH_CONNECTION_DB,
  };
};

export default buildPayload;
