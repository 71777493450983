import i18n from "../../../core/i18n";

const onlyNumbersAllowed = i18n.tc("feedback.savings.only_numbers_allowed");

const incomeRule = {
  required: true,
  message: onlyNumbersAllowed,
  trigger: "blur",
};

const desiredTimeframeRule = {
  required: true,
  trigger: "blur",
};

export default {
  desiredTimeframe: [{ ...desiredTimeframeRule }],
  owner: {
    currentSavings: [{ ...incomeRule }],
    monthlySavings: [{ ...incomeRule }],
  },
  partner: {
    currentSavings: [{ ...incomeRule }],
    monthlySavings: [{ ...incomeRule }],
  },
};
