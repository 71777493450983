
import { Component, Vue } from "vue-property-decorator";

import { compactStepLabelFor } from "../../helpers";
import sassStyles from "./steps-compact.module.scss";

@Component({
  data() {
    return {
      sassStyles,
    };
  },
})
export default class StepsCompact extends Vue {
  get stepLabel() {
    const currentPage = this.$store.getters["system/currentPage"];
    return compactStepLabelFor(currentPage);
  }
}
