
import { Vue, Component } from "vue-property-decorator";

import PasswordStrength from "@/pages/target-plan-page/registration-form/password-strength.vue";

import sassStyles from "../pages.module.scss";
import { validatePassword, validateEquality } from "./validators";
import { isPasswordStrong } from "@/modules/utils/password-strength";

@Component({
  name: "reset-password-page",
  components: { PasswordStrength },
  data() {
    return {
      sassStyles,
    };
  },
})
export default class LoginPage extends Vue {
  form = {
    newPassword: "",
    repeatedPassword: "",
  };
  rules = {
    newPassword: [{ validator: validatePassword, trigger: "blur" }],
    repeatedPassword: [
      { validator: validateEquality.bind(this), trigger: "blur" },
    ],
  };

  get isButtonActive() {
    return (
      isPasswordStrong(this.form.newPassword) &&
      this.form.newPassword === this.form.repeatedPassword
    );
  }
}
