
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

import debounce from "lodash/debounce";
import sassStyles from "./location-select.module.scss";
import api from "@habstash/habstash-frontend-api/lib/api";
import {
  PropertyAutocompleteResponseEntry,
  PropertyAutocompleteResponse,
} from "@/modules/property/types/property-autocomplete";
import ClearAddress from "./ui/clear-address/index.vue";

@Component({
  name: "location-select",
  inject: ["EventBus"],
  data() {
    return {
      sassStyles,
    };
  },
  computed: {
    ...mapGetters({
      lastSearchedLocation: "household/lastSearchedLocation",
      lastSearchedCountry: "household/lastSearchedCountry",
    }),
  },
  components: {
    ClearAddress,
  },
})
export default class extends Vue {
  isOpen = false;
  fetching = false;
  lastSearchedLocation!: string;
  lastSearchedCountry!: string;
  searchResults: PropertyAutocompleteResponse = [];
  flatSearchResults: string[] = [];
  showClearOverlay = false;

  beforeMount() {
    this.householdLocationChanged = debounce(
      this.householdLocationChanged,
      500,
    );
  }

  mounted() {
    document.addEventListener("click", this.detectOutsideClick);
    this.EventBus.$on.propertySelectedOnMap(() => {
      this.showClearOverlay = true;
    });
  }

  beforeDestroy() {
    document.removeEventListener("click", this.detectOutsideClick);
  }

  get searchTerm() {
    return this.lastSearchedLocation;
  }

  set searchTerm(val) {
    this.$store.commit("household/setLastSearchedLocation", val);
  }

  get noResults() {
    return this.searchResults.length === 0;
  }

  get country() {
    return this.lastSearchedCountry;
  }

  set country(val) {
    this.$store.commit("household/setLastSearchedCountry", val);
  }

  detectOutsideClick(e) {
    if (!this.$el.contains(e.target)) {
      this.close();
    }
  }

  householdLocationChanged(e) {
    const { value } = e.target;

    this.searchTerm = value;
    this.clearResults();
    this.fetchSuggestions(value);
  }

  householdLocationSelected(value) {
    this.$nextTick(() => {
      const outcodes = this.getLocationOutcodesArray(value);
      this.searchTerm = value;
      this.country = this.getCountry(value);
      this.$emit("location-changed", [...outcodes], this.country);
      this.close();
    });
  }

  locationInputFocused() {
    if (this.searchTerm && !this.noResults) {
      this.open();
    }
  }

  async fetchSuggestions(value) {
    try {
      this.fetching = true;
      const { data } = await api.map.search(value);
      this.processResponse(data);

      this.fetching = false;
      this.open();
    } catch ({ message }) {
      console.log(message);
    }
  }

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }

  processResponse(response: PropertyAutocompleteResponse): void {
    this.searchResults = [...response];
    this.flatSearchResults = response.map(
      (res: PropertyAutocompleteResponseEntry) => res.name,
    );
  }

  clearResults() {
    this.searchResults = [];
    this.flatSearchResults = [];
    this.$emit("location-changed", [], null);
  }

  getLocationObject(locationName) {
    return this.searchResults.find(({ name }) => {
      return name === locationName;
    });
  }

  getCountry(locationName): string {
    const location = this.getLocationObject(locationName);
    return (!!location && location.country) || "";
  }

  getLocationOutcodesArray(locationName): string[] {
    if (this.noResults) {
      return [];
    }

    const location = this.getLocationObject(locationName);
    return (!!location && location.outcodesArray) || [];
  }
}
