import i18n from "../../../../core/i18n";
import { EMAIL_REGEX } from "@/modules/app/utils/regexes";

const onlyValidEmailAllowed = i18n.tc("feedback.sign_up.only_email_allowed");

function validateEmail(_rule, value, callback) {
  if (EMAIL_REGEX.test(value)) {
    callback();
  } else {
    callback(new Error(onlyValidEmailAllowed));
  }
}

export { validateEmail };
