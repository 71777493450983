
import { Vue, Component } from "vue-property-decorator";
import AppLogo from "../app-logo.vue";
import sassStyles from "./app-header.module.scss";

@Component({
  name: "app-header",
  components: { AppLogo },
  data() {
    return {
      sassStyles,
    };
  },
})
export default class extends Vue {}
