<template>
  <div :class="sassStyles.root">
    <img
      :class="sassStyles.icon"
      :src="noResults.icon"
      width="50"
      height="50"
    />
    <p :class="sassStyles.text" v-html="noResults.text"></p>
  </div>
</template>

<script>
import { Vue, Component, Prop } from "vue-property-decorator";
import sassStyles from "./no-results.module.scss";
import oops from "./oops.png";
import yikes from "./yikes.png";

@Component({
  data() {
    return {
      sassStyles,
    };
  },
})
export default class extends Vue {
  @Prop({ type: String })
  resultType;

  get noResults() {
    const key = `pages.dream_home.search_results`;

    if (this.resultType === "no-affordability") {
      return {
        text: this.$tc(`${key}.no_affordability`),
        icon: yikes,
      };
    } else if (this.resultType === "no-accessible-results") {
      return {
        text: this.$tc(`${key}.no_results_in_affordability`),
        icon: yikes,
      };
    } else {
      return {
        text: this.$tc(`${key}.no_results_in_area`),
        icon: oops,
      };
    }
  }
}
</script>
