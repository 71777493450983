import Vue from "vue";
import VueRouter from "vue-router";
import PortalVue from "portal-vue";
import i18n from "../../core/i18n";

import App from "./app/ui/App.vue";
import store from "./store";
import router from "./app/router";
import EventBus from "./app/communication/EventBus";
import filters from "./utils/filters";
import AppLayout from "@/layouts/app-layout.vue";
import AppHeader from "@/layouts/ui/app-header/index.vue";
import AppNavigationPanel from "@/components/app-navigation-panel/index.vue";
import AppLoadingIndicator from "@/components/app-loading-indicator/index.vue";
import AppTooltip from "@/components/app-tooltip/index.vue";
import AppMap from "@/components/app-map/index.vue";
import AppModal from "@/components/app-modal/index.vue";
import AppNotifications from "@/components/notifications-list/index.vue";
import WizzardProgress from "@/components/wizzard-progress/index.vue";

import "./app/config/ant.design";
import "./app/config/match-media-queries";
import "./app/config/map";
import "./registerServiceWorker";

Vue.config.productionTip = false;

Vue.use(filters);
Vue.component("AppLayout", AppLayout);
Vue.component("AppHeader", AppHeader);
Vue.component("AppNavigationPanel", AppNavigationPanel);
Vue.component("AppLoadingIndicator", AppLoadingIndicator);
Vue.component("AppTooltip", AppTooltip);
Vue.component("AppMap", AppMap);
Vue.component("AppModal", AppModal);
Vue.component("AppNotifications", AppNotifications);
Vue.component("WizzardProgress", WizzardProgress);
Vue.use(VueRouter);
Vue.use(PortalVue);

const vueOptions = {
  store,
  router,
  i18n,
  provide: {
    EventBus: EventBus,
  },
  render: (h) => h(App),
};

window.document.title = process.env.VUE_APP_NAME || "Habstash";
Vue.prototype.$isDevelopment = process.env.NODE_ENV === "development";

const app = new Vue(vueOptions);
app.$mount("#app");
