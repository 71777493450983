<template>
  <a-row>
    <a-col :sm="12" :lg="6">
      <a-form-model
        ref="ruleForm"
        layout="vertical"
        :rules="rules"
        :model="household"
        :hideRequiredMark="true"
        @validate="onFormValidated"
      >
        <div :class="sassStyles.question">
          <h2 :class="sassStyles.questionTitle">
            {{ $t("pages.affordability.savings_so_far_title") }}
          </h2>

          <div :class="sassStyles.formModelItemsWrapper">
            <a-form-model-item
              ref="ownerCurrentSavings"
              :label="formLabel('pages.affordability.current_savings')"
              prop="owner.currentSavings"
            >
              <a-input-number
                v-model="household.owner.currentSavings"
                :min="0"
                :formatter="formatCurrency"
                :parser="parseCurrency"
                data-cy="affordability-current-savings-input"
              />
            </a-form-model-item>

            <a-form-model-item
              ref="partnerCurrentSavings"
              :label="formLabel('pages.affordability.partner_savings')"
              prop="partner.currentSavings"
              v-if="householdWithPartner"
            >
              <a-input-number
                v-model="household.partner.currentSavings"
                :min="0"
                :formatter="formatCurrency"
                :parser="parseCurrency"
                data-cy="affordability-partner-savings-input"
              />
            </a-form-model-item>
          </div>
        </div>

        <div :class="sassStyles.question">
          <h2 :class="sassStyles.questionTitle">
            {{ $t("pages.affordability.monthly_savings_title") }}
          </h2>
          <div :class="sassStyles.formModelItemsWrapper">
            <a-form-model-item
              ref="ownerMonthlySavings"
              :label="formLabel('pages.affordability.monthly_savings')"
              prop="owner.monthlySavings"
            >
              <a-input-number
                v-model="household.owner.monthlySavings"
                :min="0"
                :formatter="formatCurrency"
                :parser="parseCurrency"
                data-cy="affordability-monthly-savings-input"
              />
            </a-form-model-item>

            <a-form-model-item
              ref="partnerMonthlySavings"
              :label="formLabel('pages.affordability.partner_monthly_savings')"
              prop="partner.monthlySavings"
              v-if="householdWithPartner"
            >
              <a-input-number
                v-model="household.partner.monthlySavings"
                :min="0"
                :formatter="formatCurrency"
                :parser="parseCurrency"
                data-cy="affordability-partner-monthly-savings-input"
              />
            </a-form-model-item>
          </div>
        </div>

        <div :class="sassStyles.question">
          <h2 :class="sassStyles.questionTitle">
            {{ $t("pages.affordability.desired_timeframe_title") }}
          </h2>

          <a-form-model-item
            :label="formLabel('desired_timeframe.desired_timeframe')"
          >
            <desired-timeframe
              :value="desiredTimeframe"
              @search-option-changed="desiredTimeframeChanged"
              data-cy="affordability-desired-timeframe-select"
            />
          </a-form-model-item>
        </div>
      </a-form-model>
    </a-col>

    <mq-layout mq="xl+">
      <div :class="sassStyles.mainPicture">
        <img src="img/step02-affordability.svg" />
      </div>
    </mq-layout>
  </a-row>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";

import validationRules from "./validation-rules";
import PAGES from "@/modules/app/config/pages";
import FormMixin from "@/components/form/mixins/";
import PageMixin from "@/pages/mixins";
import DesiredTimeframe from "@/components/desired-timeframe/index.vue";
import sassStyles from "../pages.module.scss";

@Component({
  components: { DesiredTimeframe },
  computed: {
    ...mapGetters({ householdWithPartner: "household/withPartner" }),
  },
  data() {
    return {
      sassStyles,
    };
  },
  mixins: [PageMixin, FormMixin],
})
export default class extends Vue {
  pageId = PAGES.PAGE_AFFORDABILITY;
  rules = validationRules;
  desiredTimeframe = null; // TODO: move to defaults

  mounted() {
    this.loadFiltersData();
  }

  desiredTimeframeChanged(value) {
    this.household.desiredTimeframe = value;
    this.desiredTimeframe = value;
    this.validateForm();
  }

  loadFiltersData() {
    this.desiredTimeframe = this.household.desiredTimeframe;
  }

  async onFormValidated(_prop, isValid = false) {
    isValid = isValid && this.validateTimeframe();
    this.setCurrentPageValid(isValid);

    if (isValid) {
      this.$store.commit("household/update", this.household);
    }
  }

  async validateForm(isValid = false) {
    try {
      const formValid = await this.$refs.ruleForm.validate();
      isValid = formValid && this.validateTimeframe();
    } catch {
      isValid = false;
    }
    this.setCurrentPageValid(isValid);
  }

  validateTimeframe() {
    return this.desiredTimeframe !== null;
  }
}
</script>

<style lang="scss" scoped>
.ant-form-item:first-of-type {
  margin-right: 0 !important;

  @media (min-width: $mq-xl) {
    flex-direction: row;
    margin-right: $space-size-1 !important;
  }
}

.app-search {
  max-width: 100%;

  @media (min-width: $mq-xl) {
    max-width: 222px;
  }
}
</style>
