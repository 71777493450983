import PAGES from "@/modules/app/config/pages";
import store from "@/modules/store/";
import router from "@/modules/app/router";

type PageOrder = "next" | "prev" | string;

const getCurrentPageOrder = (currentPageId) => {
  return PAGES.PAGES_LIST.indexOf(currentPageId) + 1;
};

const getPageAtIndex = (n) => {
  return PAGES.PAGES_LIST[n - 1];
};

const getNextPage = (currentPage) => {
  const pageIndex = getCurrentPageOrder(currentPage);

  if (pageIndex < PAGES.PAGES_COUNT) {
    return getPageAtIndex(pageIndex + 1);
  } else {
    return PAGES.PAGE_LAST;
  }
};

const getPrevPage = (currentPage) => {
  const pageIndex = getCurrentPageOrder(currentPage);

  if (pageIndex > 1) {
    return getPageAtIndex(pageIndex - 1);
  } else {
    return PAGES.PAGE_FIRST;
  }
};

/**
 * Check if the current page is different than destination.
 * Prevents from router redirecting to the same page
 *
 * @param {string} page
 *
 * @return {boolean}
 */
export const canTransitionToPage = (page) => {
  return store.getters["system/currentPage"] !== page;
};

/**
 * Redirect to the given page
 * Update page.currentPage in the system store
 *
 * @param {string} currentPage
 * @param {string} newPage
 *
 * @return {void}
 */
export const continueTransitionToPage = (newPage: string) => {
  store.commit("system/setCurrentPage", newPage, { root: true });
  router.push({ name: newPage });
};

/**
 * Get page name
 *
 * @param {PageOrder} page
 * @param {string} currentPage
 *
 * @return next or previous page in the pages list
 */
export const getPage = (page: PageOrder) => {
  const currentPage = store.getters["system/currentPage"];

  if (page === "next") {
    return getNextPage(currentPage);
  } else {
    return getPrevPage(currentPage);
  }
};
