
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "filters-toggle",
})
export default class extends Vue {
  panelName = "filters";
  isActive = false;

  get header() {
    return this.isActive
      ? this.$t("pages.dream_home.show_less_filters")
      : this.$t("pages.dream_home.show_more_filters");
  }

  filtersToggled(panelName) {
    const open = panelName.includes(this.panelName);
    this.isActive = open;
    this.$emit("filters-toggled", open);
  }
}
