
import { Vue, Component } from "vue-property-decorator";

import { clearAllBodyScrollLocks } from "body-scroll-lock";
import validationRules from "./validation-rules.js";
import PAGES from "@/modules/app/config/pages";
import FormMixin from "@/components/form/mixins/";
import PageMixin from "@/pages/mixins";
import sassStyles from "@/pages/pages.module.scss";

@Component({
  data() {
    return {
      sassStyles,
    };
  },
  mixins: [PageMixin, FormMixin],
})
export default class extends Vue {
  rules = validationRules;
  pageId = PAGES.PAGE_AFFORDABILITY2;

  created() {
    if (["xs", "sm", "md", "lg"].includes(this.$mq)) {
      this.resetPropertyView();
    }
  }

  get householdWithPartner(): boolean {
    return this.$store.getters["household/withPartner"];
  }

  get mainImagePath(): string {
    return this.householdWithPartner
      ? "img/step03-affordability-with-partner.svg"
      : "img/step03-affordability.svg";
  }

  resetPropertyView() {
    clearAllBodyScrollLocks();
    this.$store.commit("mobile/setPropertyView", "list");
  }
}
