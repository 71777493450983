import i18n from "../../../../core/i18n";

const currencyParser = (value) => {
  return value.replace(/£\s?|(,*)/g, "");
};

const label = (labelKey) => {
  return i18n.tc(labelKey);
};

export default { currencyParser, label };
