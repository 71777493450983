import Vue from "vue";

const eventBus = new Vue();

const EVENT_TYPES = {
  pageNextPage: "page-next-page",
  fullPageOpened: "full-page-opened",
  fullPageClosed: "full-page-closed",
  setActiveFilter: "set-active-filter",
  ownerMonthlySavingsChanged: "owner-monthly-savings-changed",
  partnerMonthlySavingsChanged: "partner-monthly-savings-changed",
  desiredTimeframeChanged: "desired-timeframe-changed",
  bedroomAmountChanged: "bedroom-amount-changed",
  propertyTypeChanged: "property-type-changed",
  filtersChanged: "filters-changed",
  highlightProperty: "highlight-property",
  propertySelectedOnMap: "property-selected-on-map",
  rerenderMap: "resize-map-object",
};

export default {
  $emit: {
    pageNextPage: (payload) => {
      eventBus.$emit(EVENT_TYPES.pageNextPage, payload);
    },
    openFullPage: (pageId) => {
      eventBus.$emit(EVENT_TYPES.fullPageOpened, pageId);
    },
    setActiveFilter: (filterName) => {
      eventBus.$emit(EVENT_TYPES.setActiveFilter, filterName);
    },
    ownerMonthlySavingsChanged: (value) => {
      eventBus.$emit(EVENT_TYPES.ownerMonthlySavingsChanged, value);
    },
    partnerMonthlySavingsChanged: (value) => {
      eventBus.$emit(EVENT_TYPES.partnerMonthlySavingsChanged, value);
    },
    desiredTimeframeChanged: (value) => {
      eventBus.$emit(EVENT_TYPES.desiredTimeframeChanged, value);
    },
    bedroomAmountChanged: (value) => {
      eventBus.$emit(EVENT_TYPES.bedroomAmountChanged, value);
    },
    propertyTypeChanged: (value) => {
      eventBus.$emit(EVENT_TYPES.propertyTypeChanged, value);
    },
    filtersChanged: (value) => {
      eventBus.$emit(EVENT_TYPES.filtersChanged, value);
    },
    highlightProperty: (value) => {
      eventBus.$emit(EVENT_TYPES.highlightProperty, value);
    },
    propertySelectedOnMap: (value) => {
      eventBus.$emit(EVENT_TYPES.propertySelectedOnMap, value);
    },
    rerenderMap: (value) => {
      eventBus.$emit(EVENT_TYPES.rerenderMap, value);
    },
  },
  $on: {
    pageNextPage: (callback) =>
      eventBus.$on(EVENT_TYPES.pageNextPage, callback),
    fullPageOpened: (callback) => {
      eventBus.$on(EVENT_TYPES.fullPageOpened, callback);
    },
    fullPageClosed: (callback) => {
      eventBus.$on(EVENT_TYPES.fullPageClosed, callback);
    },
    setActiveFilter: (callback) => {
      eventBus.$on(EVENT_TYPES.setActiveFilter, callback);
    },
    ownerMonthlySavingsChanged: (callback) => {
      eventBus.$on(EVENT_TYPES.ownerMonthlySavingsChanged, callback);
    },
    partnerMonthlySavingsChanged: (callback) => {
      eventBus.$on(EVENT_TYPES.partnerMonthlySavingsChanged, callback);
    },
    desiredTimeframeChanged: (callback) => {
      eventBus.$on(EVENT_TYPES.desiredTimeframeChanged, callback);
    },
    bedroomAmountChanged: (callback) => {
      eventBus.$on(EVENT_TYPES.bedroomAmountChanged, callback);
    },
    propertyTypeChanged: (callback) => {
      eventBus.$on(EVENT_TYPES.propertyTypeChanged, callback);
    },
    filtersChanged: (callback) => {
      eventBus.$on(EVENT_TYPES.filtersChanged, callback);
    },
    highlightProperty: (callback) => {
      eventBus.$on(EVENT_TYPES.highlightProperty, callback);
    },
    propertySelectedOnMap: (callback) => {
      eventBus.$on(EVENT_TYPES.propertySelectedOnMap, callback);
    },
    rerenderMap: (callback) => {
      eventBus.$on(EVENT_TYPES.rerenderMap, callback);
    },
  },
  $off: {
    highlightProperty: () => {
      eventBus.$off(EVENT_TYPES.highlightProperty);
    },
    rerenderMap: () => {
      eventBus.$off(EVENT_TYPES.rerenderMap);
    },
  },
};
