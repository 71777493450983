
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  name: "clear-address",
  computed: {
    ...mapGetters({
      selectedProperty: "household/selectedProperty",
    }),
  },
})
export default class extends Vue {
  selectedProperty: any;
  get address() {
    return this.selectedProperty.dreamArea || "Picked address";
  }
}
