<template>
  <div :class="sassStyles.root">
    <app-button
      :class="sassStyles.backButton"
      :style="{ visibility: onFirstPage ? 'hidden' : 'visible' }"
      variant="link"
      @button-click="handleBack"
      data-cy="back-button"
    >
      <img :src="arrowLeftPath" />
      {{ $t("back") }}
    </app-button>
    <WizzardSteps />
    <app-button
      :class="sassStyles.continueButton"
      :style="{ visibility: onLastPage ? 'hidden' : 'visible' }"
      variant="primary"
      :disabled="!currentPageValid"
      @button-click="handleContinue"
      data-cy="continue-button"
    >
      {{ $t("continue_button_label") }}
      <img :src="arrowRightPath" />
    </app-button>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import WizzardSteps from "./ui/wizzard-steps/index.vue";
import arrowLeft from "./ui/arrow-left-icon.svg";
import arrowRight from "./ui/arrow-right-icon.svg";

import AppButton from "@/components/button/app-button.vue";
import PAGES from "@/modules/app/config/pages";

import sassStyles from "./wizzard-progress.module.scss";

@Component({
  name: "wizzard-progress",
  components: { AppButton, WizzardSteps },
  data() {
    return {
      sassStyles,
    };
  },
  computed: {
    ...mapGetters({
      selectedPropertyId: "household/selectedPropertyId",
    }),
  },
})
export default class extends Vue {
  arrowLeftPath = arrowLeft;
  arrowRightPath = arrowRight;

  handleContinue() {
    if (this.currentPageValid) {
      this.$store.dispatch("system/goToPage", { page: "next" });
    }
  }

  handleBack() {
    this.$store.dispatch("system/goToPage", { page: "prev" });
  }

  get currentPageValid() {
    if (this.$store.getters["system/currentPage"] !== PAGES.PAGE_DREAM_HOME) {
      return this.$store.getters["system/currentPageValid"];
    } else {
      return (
        this.$store.getters["system/currentPageValid"] &&
        this.selectedPropertyId &&
        !this.$store.getters["targetPlan/goalAlreadyMet"]
      );
    }
  }

  get onLastPage() {
    return this.$store.getters["system/currentPage"] === PAGES.PAGE_LAST;
  }

  get onFirstPage() {
    return this.$store.getters["system/currentPage"] === PAGES.PAGE_FIRST;
  }
}
</script>
