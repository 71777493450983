import Vue from "vue";
import VueMq from "vue-mq";

// TODO: define-missing-tokens; breakpoints without unit
Vue.use(VueMq, {
  breakpoints: {
    xs: 375,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1280,
    xxl: 1440,
  },
  defaultBreakpoint: "xs", // customize this for SSR
});
