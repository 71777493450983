
import { Vue, Component } from "vue-property-decorator";
import sassStyles from "../pages.module.scss";

@Component({
  name: "login-page",
  data() {
    return {
      sassStyles,
    };
  },
})
export default class LoginPage extends Vue {
  form = {
    email: "",
    password: "",
  };

  get canBeSubmitted(): boolean {
    return Boolean(this.form.email && this.form.password);
  }
}
