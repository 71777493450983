
import { Component, Prop, Vue } from "vue-property-decorator";

import DoughnutChart from "./doughnut-chart/index.vue";
import chartColors from "./chart-colors.module.scss";
import { currencyFormatter } from "@/modules/utils/formatter";

import LegendItem from "./legend-item/index.vue";

const CHART_COLORS = Object.freeze([
  chartColors.depositColor,
  chartColors.stampDutyColor,
  chartColors.legalFeesColor,
  chartColors.mortgageColor,
]);

@Component({
  name: "review-chart",
  components: { DoughnutChart, LegendItem },
})
export default class ReviewChart extends Vue {
  @Prop({ type: Array, required: true })
  dataset!: Array<number>;

  get labels() {
    return [
      this.$t("pages.target_plan.legend_deposit"),
      this.$t("pages.target_plan.legend_stamp_duty"),
      this.$t("pages.target_plan.legend_legal_fees"),
      this.$t("pages.target_plan.legend_mortgage"),
    ];
  }

  get tooltipKeys() {
    return [
      this.$t("pages.target_plan.legend_deposit_tooltip"),
      this.$t("pages.target_plan.legend_stamp_duty_tooltip"),
      this.$t("pages.target_plan.legend_legal_fees_tooltip"),
      this.$t("pages.target_plan.legend_mortgage_tooltip"),
    ];
  }

  get savingJourneyStatus() {
    const household = this.$store.getters["household/household"];
    return this.$t("pages.target_plan.chart_legend_title", {
      status: household.savingJourneyStatus,
    });
  }

  get chartData(): object {
    return {
      labels: this.labels,
      datasets: [
        {
          backgroundColor: CHART_COLORS,
          borderWidth: 0,
          weight: 2,
          data: this.dataset,
        },
      ],
    };
  }

  get legendItems(): Array<object> {
    return this.dataset.map((value, index) => {
      return {
        value: currencyFormatter(value),
        concern: this.labels[index],
        color: CHART_COLORS[index],
        tooltipKey: this.tooltipKeys[index],
      };
    });
  }

  get formattedHomeGoal() {
    const targetPlan = this.$store.getters["targetPlan/targetPlan"];
    return targetPlan.formattedHomeGoal;
  }
}
