
import { Component, Vue } from "vue-property-decorator";
import StepsWide from "./steps-wide.vue";
import StepsCompact from "./steps-compact.vue";

import sassStyles from "./wizzard-steps.module.scss";

@Component({
  name: "wizzard-steps",
  components: { StepsWide, StepsCompact },
  data() {
    return {
      sassStyles,
    };
  },
})
export default class WizzardSteps extends Vue {}
