
import { Vue, Prop, Component } from "vue-property-decorator";

import {
  min8characters,
  minOneNumber,
  minOneSpecial,
} from "@/modules/utils/password-strength";

@Component({
  name: "password-strength",
})
export default class PasswordStrength extends Vue {
  @Prop({ type: String, required: true })
  password!: string;

  get passwordHasMin8Characters(): boolean {
    return min8characters(this.password);
  }

  get passwordHasMinOneNumber(): boolean {
    return minOneNumber(this.password);
  }

  get passwordHasMinOneSpecial(): boolean {
    return minOneSpecial(this.password);
  }
}
