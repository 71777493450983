/* eslint-disable vue/no-unused-vars */
<template>
  <div :class="sassStyles.root" data-cy="wizzard-progress-wide">
    <a-breadcrumb :routes="routes">
      <span slot="separator"><img :src="arrowRightPath" /></span>
      <template slot="itemRender" slot-scope="{ route }">
        <span
          :class="{
            [sassStyles.wizzardActiveStep]: route.path === currentRoutePath,
            active: route.path === currentRoutePath,
          }"
          data-cy="wizzard-step-label"
        >
          {{ route.stepLabel }}
        </span>
      </template>
    </a-breadcrumb>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

import { routes } from "../../helpers";
import sassStyles from "./steps-wide.module.scss";
import arrowRight from "./arrow-right-icon.svg";

@Component({
  name: "steps-wide",
  data() {
    return {
      sassStyles,
    };
  },
})
export default class StepsWide extends Vue {
  arrowRightPath = arrowRight;
  get currentRoutePath() {
    return this.$route.path;
  }

  routes = routes;
}
</script>
