/* eslint-disable @typescript-eslint/no-unused-vars */
import api from "@habstash/habstash-frontend-api/lib/api";
import {
  addUniqIdToGeoJsonFeatures,
  buildGeoJsonPayload,
  categorizeProperties,
  affordabiltyCategoryFactory,
  sortPropertiesByPriority,
} from "../helpers";

const initialState = {
  properties: [],
  geoJson: {
    type: "FeatureCollection",
    features: [],
  },
  propertiesByAffordability: {
    easy: affordabiltyCategoryFactory(),
    affordable: affordabiltyCategoryFactory(),
    stretch: affordabiltyCategoryFactory(),
  },
  fetchingProperties: false,
  fetchingPropertiesGeoJson: false,
  resultType: "no-results",
  activePropertyCategory: "affordable",
  layersVisibility: {
    easy: false,
    affordable: true,
    stretch: false,
  },
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions: {
    async ["fetchProperties"]({ commit }, outcodes) {
      try {
        commit("setFetchingProperties", true);
        const payload = buildGeoJsonPayload(outcodes);
        const data = await api.map.fetchProperties(payload);
        const dataWithUniqId = addUniqIdToGeoJsonFeatures(data);
        const { easy, affordable, stretch } =
          categorizeProperties(dataWithUniqId);

        commit("setResultType", {
          featuresCount: data.features.length,
          beyondAffordability: data.beyondAffordability,
        });

        if (!dataWithUniqId.error) {
          commit("setProperties", { easy, affordable, stretch });
          commit("setPropertiesGeoJson", dataWithUniqId);
        }
      } catch ({ message }) {
        console.log(message);
      } finally {
        commit("setFetchingProperties", false);
      }
    },
  },
  mutations: {
    setProperties(state, { easy, affordable, stretch }) {
      // TODO: Clean up all properties after implementing categories
      state.properties = [
        ...easy.properties,
        ...affordable.properties,
        ...stretch.properties,
      ];
      state.propertiesByAffordability.easy = easy;
      state.propertiesByAffordability.affordable = affordable;
      state.propertiesByAffordability.stretch = stretch;
    },
    setPropertiesGeoJson(state, payload) {
      state.geoJson = payload;
    },
    setFetchingProperties(state, payload) {
      state.fetchingProperties = payload;
    },
    setResultType(state, { featuresCount, beyondAffordability }) {
      let resultType;

      if (featuresCount === 0 && !beyondAffordability) {
        resultType = "no-results";
      } else if (featuresCount === 0 && beyondAffordability) {
        resultType = "no-accessible-results";
      } else {
        resultType = "with-results";
      }

      state.resultType = resultType;
    },
    setActivePropertyCategory(state, category) {
      const layersVisibility = {
        easy: false,
        affordable: false,
        stretch: false,
      };
      layersVisibility[category] = true;
      state.layersVisibility = layersVisibility;
      state.activePropertyCategory = category;
    },
  },
  getters: {
    properties: (state) => state.properties,
    easyProperties: (state) => state.propertiesByAffordability.easy.properties,
    affordableProperties: (state) =>
      state.propertiesByAffordability.affordable.properties,
    stretchProperties: (state) =>
      state.propertiesByAffordability.stretch.properties,
    propertyById: (state) => (id) => {
      return state.properties.find((property) => property.id === id);
    },
    propertyByFeature: (state, getters) => (geojsonFeature) => {
      const features = getters.geoJsonFeatures;

      if (!features.length) {
        return null;
      }

      return getters.propertyById(geojsonFeature.id);
    },
    propertiesByPriority: (state, getters) => {
      return sortPropertiesByPriority({
        easyProperties: getters.easyProperties,
        affordableProperties: getters.affordableProperties,
        stretchProperties: getters.stretchProperties,
      });
    },
    propertiesByAffordability: (state) => state.propertiesByAffordability,
    geoJson: (state) => state.geoJson,
    geoJsonFeatures: (state, getters) => getters.geoJson.features,
    fetchingProperties: (state) => state.fetchingProperties,
    noProperties: (state) => state.properties.length === 0,
    resultType: (state) => state.resultType,
    activePropertyCategory: (state) => state.activePropertyCategory,
    layersVisibility: (state) => state.layersVisibility,
    geoJsonEasy: (state) => state.propertiesByAffordability.easy.geoJson,
    geoJsonAffordable: (state) =>
      state.propertiesByAffordability.affordable.geoJson,
    geoJsonStretch: (state) => state.propertiesByAffordability.stretch.geoJson,
  },
};
