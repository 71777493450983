
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import SelectEntry from "./ui/select-entry.vue";

interface Option {
  key: string;
  value: string;
}

const DEFAULT_VALUE = [];

@Component({
  name: "multiple-select",
  components: { SelectEntry },
})
export default class extends Vue {
  @Prop({ default: "select", type: String })
  placeholderKey!: string;

  @Prop() options!: Array<Option>;
  @Prop({ required: false }) defaultValue!: Option[];
  @Prop({ required: true })
  value!: Option[];

  open = false;
  allOption = {
    value: "all",
    label: "All",
  };
  selectedValue = this.value || DEFAULT_VALUE;

  selectIconPath = {
    selectOpen: "img/search-icon.svg",
    selectClosed: "img/arrow-up-icon.svg",
  };

  @Watch("value")
  valueUpdated() {
    this.selectedValue = [...this.value];
  }

  mounted() {
    if (this.defaultValue) {
      this.assignOptions([...this.defaultValue]);
    }
  }

  get placeholder() {
    return this.$t(this.placeholderKey);
  }

  get allOptionSelected() {
    return this.selectedValue.length === this.options.length;
  }

  get allValues() {
    return this.options.map((v) => v.value);
  }

  get iconPath() {
    return this.open
      ? this.selectIconPath.selectOpen
      : this.selectIconPath.selectClosed;
  }

  onDropdownVisivilityChange(open = false) {
    this.open = open;
  }

  onChange(values) {
    if (values.includes(this.allOption.value)) {
      return;
    }

    this.assignOptions(values);
  }

  toggleSelection() {
    const values = this.allOptionSelected ? [] : this.allValues;
    this.$nextTick(() => {
      this.assignOptions(values);
    });
  }

  assignOptions(options) {
    Object.assign(this, {
      selectedValue: [...options],
    });

    this.$emit("multiple-select-value-changed", options);
  }
}
