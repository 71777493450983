
import { Vue, Component } from "vue-property-decorator";

import { EMAIL_REGEX } from "@/modules/app/utils/regexes";
import validationRules from "./validation-rules";
import sassStyles from "../pages.module.scss";

@Component({
  name: "forgot-password-page",
  data() {
    return {
      sassStyles,
    };
  },
})
export default class LoginPage extends Vue {
  form = {
    email: "",
  };
  rules = validationRules;

  get isValidEmail(): boolean {
    return EMAIL_REGEX.test(this.form.email);
  }
}
