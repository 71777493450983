
import { Vue, Component } from "vue-property-decorator";

import sassStyles from "../pages.module.scss";

@Component({
  name: "forgot-password-inbox",
  data() {
    return {
      sassStyles,
    };
  },
})
export default class ForgotPasswordInboxPage extends Vue {}
