
import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";

import CardsSelect from "./cards-select/index.vue";
import ViewSwitch from "@/components/mobile/results-view-switch/index.vue";

@Component({
  name: "result-tabs",
  inject: ["EventBus"],
  components: { CardsSelect, ViewSwitch },
  computed: {
    ...mapGetters({
      selectedPropertyId: "household/selectedPropertyId",
      easyProperties: "property/easyProperties",
      affordableProperties: "property/affordableProperties",
      stretchProperties: "property/stretchProperties",
      activePropertyCategory: "property/activePropertyCategory",
    }),
  },
})
export default class ResultTabs extends Vue {
  activePropertyCategory!: any;

  get viewSwitchOptions() {
    return [
      { label: "List", value: "list" },
      { label: "Map", value: "map-collapsed" },
    ];
  }

  tabChanged(name) {
    this.$store.commit("property/setActivePropertyCategory", name);
  }

  selectResultId(propertyId) {
    this.$store.commit("household/setPropertyId", propertyId);
    this.$store.commit("household/updateSelectedPropertyReadAt");
  }
}
