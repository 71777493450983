import Notification from "../types";

type NotificationType = "success" | "error";

export default class NotificationMessage implements Notification {
  public id!: number;

  constructor(
    public message: string,
    public type: NotificationType = "success",
    public html: boolean = true,
  ) {
    this.id = new Date().getTime();

    return {
      message: this.message,
      type: this.type,
      id: this.id,
      html: this.html,
    };
  }
}
