import i18n from "../../../../../../core/i18n";

const LABEL_KEY = "pages.dream_home.filters.property_type.options";

function generatePropertyTypeOptions() {
  return [
    { value: "flat", label: i18n.tc(`${LABEL_KEY}.flat`) },
    { value: "house", label: i18n.tc(`${LABEL_KEY}.house`) },
  ];
}
const propertyTypeOptions = generatePropertyTypeOptions();
export { propertyTypeOptions };
