
import { Component, Prop, Vue } from "vue-property-decorator";
// tslint:disable-nextline
import sassStyles from "./app-button.module.scss";

@Component({
  name: "app-button",
  data() {
    return {
      sassStyles,
    };
  },
})
export default class AppButton extends Vue {
  @Prop({ default: "default", type: String })
  variant!: string;

  @Prop({ default: false, type: Boolean })
  outline!: boolean;

  @Prop({ default: false, type: Boolean })
  disabled!: boolean;
}
