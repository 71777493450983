import i18n from "../../../core/i18n";

const onlyNumbersAllowed = i18n.tc("feedback.savings.only_numbers_allowed");

const savingsRule = {
  required: true,
  message: onlyNumbersAllowed,
  trigger: "blur",
};

export default {
  owner: {
    yearlyIncome: [{ ...savingsRule }],
    additionalMoney: [{ ...savingsRule }],
  },
  partner: {
    yearlyIncome: [{ ...savingsRule }],
    additionalMoney: [{ ...savingsRule }],
  },
};
