/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/camelcase */
import TargetPlan from "../model";
import buildCreateAccountPayload from "../helpers/build-create-account-payload";
import calculateHousePricePayload from "../helpers/calculate-house-price-payload";
import reverseCalculateHousePricePayload from "../helpers/reverse-calculate-house-price-payload";
import api from "@habstash/habstash-frontend-api/lib/api/index";

const stretchPlan = {
  increaseMonthlyContribution: 0,
  increaseTime: 0,
  stretchedBy: "",
  updated: false,
};

const initialState = {
  data: new TargetPlan(),
  stretchPlan,
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions: {
    async ["reverseCalculateHousePrice"](
      { state, commit },
      { household, dreamHomePrice },
    ) {
      const payload = reverseCalculateHousePricePayload(
        household,
        dreamHomePrice,
      );
      state.stretchPlan.updated = false;
      try {
        const { data } = await api.calculations.reverseCalculateHousePrice(
          payload.owner,
          payload.partner,
          ''
        );

        if (!data.error) {
          commit("updateGoal", { payload: data });
        }
      } catch ({ message }) {
        console.log(message);
      }
    },
    async ["calculateStretchPlan"](
      { state, commit, getters, rootGetters },
      stretchBy,
    ) {
      const dreamHomePrice = rootGetters["household/selectedProperty"].price;
      const household = getters["stretchDreamPlan"](stretchBy);

      const payload = reverseCalculateHousePricePayload(
        household,
        dreamHomePrice,
      );

      try {
        await api.calculations.reverseCalculateHousePrice(
          payload.owner,
          payload.partner,
          ''
        );
        commit("updateStretchPlan", { stretchedBy: stretchBy });
      } catch ({ message }) {
        console.log(message);
      }
    },
    async ["calculateHousePrice"]({ commit }, household) {
      const payload = calculateHousePricePayload(household);
      try {
        const { data } = await api.calculations.calculateHousePrice(
          payload.owner,
          payload.partner,
        );

        commit("update", { payload: data });
      } catch ({ message }) {
        console.log(message);
      }
    },
    async ["register"]({ commit, dispatch }, formData) {
      const payload = buildCreateAccountPayload(formData);

      try {
        const authData: any = await api.auth.register(payload);

        const accountPayload = {
          id: authData.data._id,
          username: payload.email,
          password: payload.password,
          firstName: payload.user_metadata.name,
          lastName: "",
          telephone: "",
          client_id: process.env.VUE_APP_AUTH_CLIENT_ID as string,
          connection: process.env.VUE_APP_AUTH_CONNECTION_DB as string,
        };

        // eslint-disable-next-line
        const data = await api.account.create(accountPayload);
        if (process.env.NODE_ENV === "development") {
          console.log(console.log("registered", data));
        }

        commit(
          "system/addNotification",
          {
            message:
              "Almost there… Check your email to confirm the account.",
          },
          { root: true },
        );

        dispatch(
          "housing/create",
          { userUid: authData.data._id },
          { root: true },
        );
      } catch (error) {
        if (error && error.code === "user_exists") {
          commit(
            "system/addNotification",
            {
              message:
                "Sorry, that user account already exists. Try another email address.",
              type: "error",
            },
            { root: true },
          );
        }
      }
    },
  },
  mutations: {
    update(state, { payload }) {
      state.data.affordableHomePrice = payload.affordableHomePrice;
      state.data.savingPeriod = payload.moveInMonths;
      state.data.monthlySavings = payload.deposit.monthlyContribution;
      state.data.estimatedTargetMoveinDate = payload.estimatedMoveInDate;
      state.data.legalFees = payload.deposit.legalFees;
      state.data.stampDuty = payload.deposit.stampDuty;
      state.data.mortgageFees = payload.deposit.mortgageFees;
      state.data.surveyorCosts = payload.deposit.surveyorCosts;
    },
    updateGoal(state, { payload }) {
      const {
        goalAlreadyMet,
        increaseMonthlyContribution,
        increaseTime,
        depositPercentage,
        increaseTimeEstimatedMoveInDate,
      } = payload;
      state.data.goalAlreadyMet = goalAlreadyMet;
      state.data.depositPercentage = depositPercentage;

      state.stretchPlan.increaseMonthlyContribution =
        increaseMonthlyContribution;
      state.stretchPlan.increaseTime = increaseTime;
      state.data.increaseTimeEstimatedMoveInDate =
        increaseTimeEstimatedMoveInDate;

      if (payload.deposit) {
        state.data.homeGoal = payload.deposit.homeGoal;
        state.data.deposit = payload.deposit.deposit;
        state.data.legalFees = payload.deposit.legalFees;
        state.data.stampDuty = payload.deposit.stampDuty;
        state.data.mortgageFees = payload.deposit.mortgageFees;
        state.data.surveyorCosts = payload.deposit.surveyorCosts;
      }
      state.stretchPlan.updated = true;
    },
    updateStretchPlan(state, payload) {
      return (state.stretchPlan = { ...state.stretchPlan, ...payload });
    },
  },
  getters: {
    targetPlan: (state) => state.data,
    stretchPlan: (state) => state.stretchPlan,
    goalAlreadyMet: (state) => state.data.goalAlreadyMet,
    stretchPlanUpdated: (state) => state.stretchPlan.updated,
    increaseMonthlyContribution: (state) => {
      return state.stretchPlan.increaseMonthlyContribution;
    },
    increaseTime: (state) => {
      return state.stretchPlan.increaseTime;
    },
    stretchDreamPlan:
      (state, getters, rootState, rootGetters) => (stretchBy) => {
        const household = rootGetters["household/household"];

        if (stretchBy === "moveInLater") {
          household.desiredTimeframe = getters["increaseTime"];
        } else if (stretchBy === "increaseSaving") {
          const increasedContribution =
            getters["increaseMonthlyContribution"] -
            household.calculatedSavings;
          household.owner.monthlySavings += increasedContribution;
        }

        return household;
      },
    targetMoveinDate: (state, getters, rootState, rootGetters) => {
      if (getters["stretchPlan"].stretchedBy === "increaseSaving") {
        return getters["targetPlan"].estimatedTargetMoveinDate;
      } else {
        return getters["targetPlan"].increaseTimeEstimatedMoveInDate;
      }
    },
  },
};
