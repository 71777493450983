const PAGE_HOME_STATUS = "home-status";
const PAGE_AFFORDABILITY = "affordability";
const PAGE_AFFORDABILITY2 = "affordability2";
const PAGE_DREAM_HOME = "dream-home";
const PAGE_TARGET_PLAN = "target-plan";
const PAGE_FIRST = PAGE_HOME_STATUS;
const PAGE_LAST = PAGE_TARGET_PLAN;

const PAGE_HOME_STATUS_PATH = "/";
const PAGE_AFFORDABILITY_PATH = "/affordability";
const PAGE_AFFORDABILITY2_PATH = "/affordability-2";
const PAGE_DREAM_HOME_PATH = "/dream-home";
const PAGE_TARGET_PLAN_PATH = "/target-plan";

const PAGES_LIST = [
  PAGE_HOME_STATUS,
  PAGE_AFFORDABILITY,
  PAGE_AFFORDABILITY2,
  PAGE_DREAM_HOME,
  PAGE_TARGET_PLAN,
];

const PAGES_COUNT = PAGES_LIST.length;

export default {
  PAGE_HOME_STATUS,
  PAGE_AFFORDABILITY,
  PAGE_AFFORDABILITY2,
  PAGE_DREAM_HOME,
  PAGE_TARGET_PLAN,
  PAGE_TARGET_PLAN_PATH,
  PAGE_FIRST,
  PAGE_LAST,
  PAGES_LIST,
  PAGES_COUNT,
  PAGE_HOME_STATUS_PATH,
  PAGE_AFFORDABILITY_PATH,
  PAGE_AFFORDABILITY2_PATH,
  PAGE_DREAM_HOME_PATH,
};
