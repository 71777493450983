<template>
  <div class="page-target-plan">
    <a-row>
      <a-col :sm="12" :lg="7">
        <h2 :class="sassStyles.questionTitle">
          {{ $t("pages.target_plan.header") }}
        </h2>
        <p class="question-paragraph">
          {{ $t("pages.target_plan.description") }}
        </p>
      </a-col>
    </a-row>

    <div class="card">
      <div class="card-page">
        <h4 class="card-header">
          {{ $t("pages.target_plan.review_header") }}
        </h4>
        <hr class="separator only-xl" />
        <p class="review-description" v-html="reviewDescription"></p>

        <review-chart :dataset="targetPlan.chartData" />

        <hr class="separator" />

        <h3 class="summary-title">
          {{ $t("pages.target_plan.dream_home_header") }}
        </h3>

        <div class="summary">
          <div class="summary-item">
            <span class="summary-topic">
              {{ $t("pages.target_plan.dream_area") }}
            </span>
            <span class="summary-value">
              {{ selectedProperty.dreamArea }}
            </span>
          </div>
          <div class="summary-item">
            <span class="summary-topic">
              {{ $t("pages.target_plan.type_of_home") }}
            </span>
            <span class="summary-value">
              {{ selectedProperty.typeOfHome }}
            </span>
          </div>
          <div class="summary-item">
            <span class="summary-topic">
              {{ $t("pages.target_plan.average_price") }}
            </span>
            <span class="summary-value">
              {{ selectedProperty.formattedPrice }}
            </span>
          </div>
          <div class="summary-item">
            <span class="summary-topic">
              {{ $t("pages.target_plan.target_move_in") }}
            </span>
            <span class="summary-value">
              {{ targetMoveinDate | shortDate }}
            </span>
          </div>
        </div>

        <hr class="separator" />

        <div class="hint">*{{ depositPercentageDescription }}</div>
        <div class="additional-info" v-if="!isPropertyStretch">
          {{ $t("pages.target_plan.additional_info") }}
        </div>
      </div>
      <div class="card-page">
        <h4 class="card-header">
          {{ $t("pages.target_plan.create_account_header") }}
        </h4>

        <registration-form />
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";

import { clearAllBodyScrollLocks } from "body-scroll-lock";
import PageMixin from "@/pages/mixins";
import ReviewChart from "./review-chart/index.vue";
import RegistrationForm from "./registration-form/index.vue";
import { periodInWords } from "@/modules/utils/date_helpers";
import sassStyles from "@/pages/pages.module.scss";

@Component({
  components: { ReviewChart, RegistrationForm },
  data() {
    return { sassStyles };
  },
  mixins: [PageMixin],
  computed: {
    ...mapGetters({
      targetPlan: "targetPlan/targetPlan",
      stretchPlan: "targetPlan/stretchPlan",
      targetMoveinDate: "targetPlan/targetMoveinDate",
      selectedProperty: "household/selectedProperty",
    }),
  },
})
export default class extends Vue {
  created() {
    clearAllBodyScrollLocks();

    if (["xs", "sm", "md", "lg"].includes(this.$mq)) {
      this.resetPropertyView();
    }
  }

  get reviewDescription() {
    const descriptionKey = this.household.withPartner
      ? "review_description_with_partner"
      : "review_description";

    return this.$t(`pages.target_plan.${descriptionKey}`, {
      amount: this.household.calculatedSavings,
      period: this.period,
    });
  }

  get isPropertyStretch() {
    return this.selectedProperty.affordability == 2;
  }

  get period() {
    let _period;

    if (this.isPropertyStretch) {
      _period = this.household.desiredTimeframe;
    } else {
      _period = this.stretchPlan.increaseTime;
    }
    return periodInWords(_period);
  }

  get depositPercentageDescription() {
    return this.$t(`pages.target_plan.hint`, {
      depositPercentage: this.targetPlan.depositPercentage,
    });
  }

  resetPropertyView() {
    this.$store.commit("mobile/setPropertyView", "list");
  }
}
</script>

<style lang="scss" scoped>
$medium-border-radius: 10px;

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 0;
  box-sizing: border-box;
  border-radius: $medium-border-radius;
  margin: 0 10px 20px !important;

  &::before {
    @media (min-width: $mq-xl) {
      content: "";
      width: 1px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      right: 50%;
      background: $color-base-gray-100;
    }
  }

  @media (min-width: $mq-xl) {
    border: 1px solid $color-base-gray-100;
    flex-direction: row;
  }
}

.card-page {
  flex: 1;
  border: 1px solid $color-base-gray-100;
  border-radius: $medium-border-radius;
  margin-bottom: 20px;
  padding: 40px 20px;

  @media (min-width: $mq-xl) {
    border: 0;
    padding: 50px 40px;
  }
}

.card-header {
  text-align: center;
  font-size: 20px;
  font-weight: $font-weight-bold;
  margin-bottom: 20px;

  @media (min-width: $mq-xl) {
    font-size: $font-size-4;
  }
}

.question-paragraph {
  font-size: $font-size-mobile-3;

  @media (min-width: $mq-xl) {
    font-size: $font-size-3;
    margin-bottom: $space-size-2;
  }
}

.review-description {
  margin-top: 20px;
  font-size: $font-size-mobile-3;

  @media (min-width: $mq-xl) {
    font-size: $font-size-3;
  }
}

.summary-title {
  margin: 20px 0;
  font-size: $font-size-5;

  @media (min-width: $mq-xl) {
    font-size: $font-size-3;
  }
}

.summary-item {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-bottom: 10px;
  flex-direction: column;

  &:not(:last-of-type) {
    border-bottom: 1px solid $color-base-gray-100;
  }

  @media (min-width: $mq-xl) {
    flex-direction: row;
    margin-right: $space-size-1;

    &:not(:last-of-type) {
      border-bottom: 0;
    }
  }
}

.summary-topic {
  margin-bottom: 5px;
  font-size: $font-size-mobile-3;

  @media (min-width: $mq-xl) {
    font-size: $font-size-2;
  }
}

.summary-value {
  font-size: $font-size-4;
  font-weight: $font-weight-bold;
}

.hint,
.additional-info {
  font-size: $font-size-mobile-3;
  color: $color-supplementary-gray-100;
}

.hint {
  margin: 20px 0;

  @media (min-width: $mq-xl) {
    font-size: $font-size-2;
  }
}

.additional-info {
  margin-bottom: $space-size-15;

  @media (min-width: $mq-xl) {
    font-size: $font-size-3;
    margin-bottom: 0;
  }
}

.privacy-policy {
  margin: 70px auto 0;
  max-width: 250px;
  font-size: $font-size-mobile-1;
  line-height: $line-height-1;
  text-align: center;
  color: $color-supplementary-gray-100;

  @media (min-width: $mq-xl) {
    margin: 180px auto 0;
  }
}
</style>
