import L from "leaflet";
import i18n from "../../../core/i18n";

import { currencyFormatter } from "@/modules/utils/formatter";

const AFFORDABILITY_EASY = 0;
const AFFORDABILITY_AFFORDABLE = 1;
const AFFORDABILITY_STRETCH = 2;

const AFFORDABILITY = new Map([
  [AFFORDABILITY_EASY, "Easy"],
  [AFFORDABILITY_AFFORDABLE, "Affordable"],
  [AFFORDABILITY_STRETCH, "Stretch"],
]);

export default class {
  constructor(params = {}) {
    this.id = params.id;
    this.propertyType = params.propertyType || "house";
    this.bedroomsAmount = params.bedroomsAmount || 1;
    this.location = params.location || "–";
    this.price = params.price || 0;
    this.coordinates = params.coordinates || [];
    this.affordability = params.affordability || AFFORDABILITY_EASY;
    this.lat = this.coordinates[1];
    this.lng = this.coordinates[0];

    Object.freeze(this.coordinates);
  }

  get formattedPrice() {
    return currencyFormatter(this.price);
  }

  get affordabilityName() {
    return AFFORDABILITY.get(this.affordability);
  }

  get latLngBound() {
    return L.latLng({ lat: this.lat, lng: this.lng });
  }

  get propertyTypeName() {
    return i18n.tc(`property.property_types.${this.propertyType}`);
  }

  get propertyTypeDescription() {
    return [
      this.bedroomsAmount,
      i18n.tc("bed"),
      i18n.tc(`property.property_types.${this.propertyType}`),
    ].join(" ");
  }
}
