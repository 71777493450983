import i18n from "../../../../core/i18n";
import { validateEmail } from "@/modules/app/utils/validators";
import { isPasswordStrong } from "@/modules/utils/password-strength";

const onlyLettersAllowed = i18n.tc("feedback.sign_up.only_letters_allowed");

function validateOnlyText(_rule, value, callback) {
  if (/^[a-zA-Z]+$/.test(value)) {
    callback();
  } else {
    callback(new Error(onlyLettersAllowed));
  }
}

function validatePassword(_rule, value, callback) {
  if (isPasswordStrong(value)) {
    callback();
  } else {
    callback(new Error());
  }
}

const lettersRule = {
  validator: validateOnlyText,
  trigger: "blur",
};

const emailRule = {
  validator: validateEmail,
  trigger: "blur",
};

const passwordRule = {
  validator: validatePassword,
  trigger: "blur",
};

export default {
  username: [{ ...lettersRule }],
  email: [{ ...emailRule }],
  password: [{ ...passwordRule }],
};
