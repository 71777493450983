export default {
  map: {
    mapOptions: {
      zoomControl: false,
      attributionControl: false,
      dragging: true,
    },
    initialZoom: 6,
    minZoom: 6,
    maxZoom: 18,
  },
  tileProvider: {
    name: "OpenStreetMap",
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  },
  controlZoom: {
    position: "topright",
  },
};
