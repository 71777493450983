import PAGES from "@/modules/app/config/pages";
import i18n from "../../../../core/i18n";

const pagesConfig = (version = "wide") => {
  return {
    [PAGES.PAGE_HOME_STATUS]: {
      label: `breadcrumbs.${version}.home_status`,
      path: PAGES.PAGE_HOME_STATUS_PATH,
    },
    [PAGES.PAGE_AFFORDABILITY]: {
      label: `breadcrumbs.${version}.affordability`,
      path: PAGES.PAGE_AFFORDABILITY_PATH,
    },
    [PAGES.PAGE_AFFORDABILITY2]: {
      label: `breadcrumbs.${version}.affordability2`,
      path: PAGES.PAGE_AFFORDABILITY2_PATH,
    },
    [PAGES.PAGE_DREAM_HOME]: {
      label: `breadcrumbs.${version}.dream_home`,
      path: PAGES.PAGE_DREAM_HOME_PATH,
    },
    [PAGES.PAGE_TARGET_PLAN]: {
      label: `breadcrumbs.${version}.target_plan`,
      path: PAGES.PAGE_TARGET_PLAN_PATH,
    },
  };
};

const compactPageConfig = { ...pagesConfig("compact") };
const widePageConfig = { ...pagesConfig("wide") };

const mapPagesToBreadcrumbRoutes = () => {
  return Object.keys(widePageConfig).map((name) => ({
    stepLabel: i18n.tc(widePageConfig[name].label),
    path: widePageConfig[name].path,
  }));
};

export const compactStepLabelFor = (name = PAGES.PAGE_HOME_STATUS) => {
  const key = compactPageConfig[name].label;
  return i18n.tc(key);
};

export const routes = mapPagesToBreadcrumbRoutes();
