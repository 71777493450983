import { requiredRule, onlyNumbersRule } from "../validation-rules";

export default {
  desiredPlace: [{ ...requiredRule }],
  owner: {
    monthlySavings: [{ ...onlyNumbersRule }],
  },
  partner: {
    monthlySavings: [{ ...onlyNumbersRule }],
  },
};
