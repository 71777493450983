<template>
  <div>
    <a-form-model
      ref="ruleForm"
      layout="vertical"
      :rules="rules"
      :model="household"
      :hideRequiredMark="true"
      @validate="onFormValidated"
    >
      <div :class="sassStyles.question">
        <h2 :class="sassStyles.questionTitle">
          {{ $t("pages.dream_home.explore_areas_title") }}
        </h2>

        <div :class="sassStyles.desiredPlaceWrapper">
          <a-form-model-item ref="desiredPlace" prop="desiredPlace">
            <location-select
              data-cy="location-select"
              @location-changed="locationChanged"
            />
          </a-form-model-item>
        </div>

        <mq-layout
          :mq="['xs', 'sm', 'md']"
          :class="sassStyles.additionalFiltersWrapper"
        >
          <div
            :class="sassStyles.mobileFilterWrapper"
            @click.stop.prevent="setActiveFilter('totalSavings')"
          >
            <monthly-savings-select />
          </div>

          <div
            :class="sassStyles.mobileFilterWrapper"
            @click.stop.prevent="setActiveFilter('desiredTimeframe')"
          >
            <a-form-model-item
              :label="formLabel('desired_timeframe.desired_timeframe')"
            >
              <desired-timeframe :value="desiredTimeframe" />
            </a-form-model-item>
          </div>
        </mq-layout>

        <mq-layout mq="lg+" :class="sassStyles.additionalFiltersWrapper">
          <monthly-savings-select :class="sassStyles.totalMonthlySavings" />

          <a-form-model-item
            :label="formLabel('desired_timeframe.desired_timeframe')"
            prop="desiredTimeframe"
            :class="sassStyles.desiredTimeframe"
          >
            <desired-timeframe
              :value="desiredTimeframe"
              @search-option-changed="desiredTimeframeChanged"
            />
          </a-form-model-item>
        </mq-layout>
      </div>
    </a-form-model>

    <dream-home-filters />

    <app-button
      variant="primary"
      :class="sassStyles.searchButton"
      :disabled="fetchingProperties || !selectedOutcodes.length"
      @button-click="fetchProperties"
    >
      {{ $t("pages.dream_home.search_results.search") }}
    </app-button>

    <search-hint :class="sassStyles.searchHint" v-if="showSearchHint" />

    <div :class="sassStyles.loadingIndicator" v-if="fetchingProperties">
      <app-loading-indicator />
    </div>

    <calculated-affordability
      v-if="displayAffordability"
      :amount="targetPlan.formattedAffordableHomePrice"
    />
    <template v-if="!noProperties">
      <p class="calculatedAffordabiliyDescription">
        {{ $t("pages.dream_home.calculated_affordability_description") }}
      </p>
      <result-tabs />
    </template>

    <no-results :resultType="resultType" v-if="noProperties && !firstVisit" />

    <portal to="modals">
      <whoa-modal v-if="showWhoaModal" @close="showWhoaModal = false" />
      <overachiever-modal
        v-if="showOverachieverModal"
        @close="showOverachieverModal = false"
      />
    </portal>
  </div>
</template>

<script>
import { Vue, Component, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import validationRules from "./validation-rules";
import PAGES from "@/modules/app/config/pages";
import FormMixin from "@/components/form/mixins/";
import PageMixin from "@/pages/mixins";
import LocationSelect from "./ui/location-select/index.vue";
import ResultTabs from "./ui/result-tabs/index.vue";
import MonthlySavingsSelect from "./ui/monthly-savings-select/index.vue";
import DreamHomeFilters from "./ui/dream-home-filters/index.vue";
import SearchHint from "./ui/search-hint/index.vue";
import DesiredTimeframe from "@/components/desired-timeframe/index.vue";
import AppButton from "@/components/button/app-button.vue";
import NoResults from "./ui/no-results/in-search-area.vue";
import CalculatedAffordability from "./ui/calculated-affordability/index.vue";
import WhoaModal from "./ui/modals/whoa-modal/index.vue";
import OverachieverModal from "./ui/modals/overachiever-modal/index.vue";

import sassStyles from "./dream-home-page.module.scss";

@Component({
  data() {
    return {
      sassStyles,
    };
  },
  mixins: [PageMixin, FormMixin],
  components: {
    LocationSelect,
    DesiredTimeframe,
    DreamHomeFilters,
    ResultTabs,
    MonthlySavingsSelect,
    SearchHint,
    AppButton,
    NoResults,
    CalculatedAffordability,
    WhoaModal,
    OverachieverModal,
  },
  computed: {
    ...mapGetters({
      totalMonthlySavings: "household/totalMonthlySavings",
      fetchingProperties: "property/fetchingProperties",
      targetPlan: "targetPlan/targetPlan",
      noProperties: "property/noProperties",
      propertyResultType: "property/resultType",
      selectedOutcodes: "household/lastSelectedOutcodes",
      selectedPropertyId: "household/selectedPropertyId",
      selectedPropertyReadAt: "household/selectedPropertyReadAt",
      goalAlreadyMet: "targetPlan/goalAlreadyMet",
      stretchPlanUpdated: "targetPlan/stretchPlanUpdated",
    }),
  },
})
export default class extends Vue {
  pageId = PAGES.PAGE_DREAM_HOME;
  rules = validationRules;
  desiredTimeframe = 12; // TODO: move to defaults
  firstVisit = true;
  showOverachieverModal = false;
  showWhoaModal = false;

  async created() {
    this.calculateHousePrice();
  }

  mounted() {
    this.EventBus.$on.filtersChanged((household) => {
      this.$store.commit("household/update", household);
      this.desiredTimeframe = household.desiredTimeframe;
      this.validateForm();
    });

    this.EventBus.$on.ownerMonthlySavingsChanged((value) => {
      this.household.owner.monthlySavings = value;
      this.validateForm();
    });

    this.EventBus.$on.partnerMonthlySavingsChanged((value) => {
      this.household.partner.monthlySavings = value;
      this.validateForm();
    });

    this.loadFiltersData();
  }

  get selectedProperty() {
    return this.$store.getters["property/propertyById"](
      this.selectedPropertyId,
    );
  }

  canShowWhoaModal() {
    return (
      [0, 1].includes(this.selectedProperty.affordability) &&
      this.goalAlreadyMet
    );
  }

  canShowOverreacherModal() {
    return this.selectedProperty.affordability === 2;
  }

  @Watch("selectedProperty.id")
  getTargetPlan() {
    if (!this.selectedProperty) {
      return;
    }

    this.$store.dispatch("targetPlan/reverseCalculateHousePrice", {
      household: this.household,
      dreamHomePrice: this.selectedProperty.price,
    });
  }

  // selectedProperty.id is cached
  // overreacher should open every time
  // user clicks on the property card.
  @Watch("selectedPropertyReadAt")
  toggleOverreacherModalVisibility() {
    if (this.canShowOverreacherModal()) {
      this.showOverachieverModal = true;
    }
  }

  @Watch("stretchPlanUpdated")
  toggleAffordabilityModalVisibility() {
    if (!this.stretchPlanUpdated) {
      return;
    }

    if (this.canShowOverreacherModal()) {
      this.showOverachieverModal = true;
    } else if (this.canShowWhoaModal()) {
      this.showWhoaModal = true;
    }
  }

  @Watch("showWhoaModal")
  bodyScrollForWhoa(flag) {
    this.toggleBodyScroll("whoa-modal", flag);
  }

  @Watch("showOverachieverModal")
  bodyScrollForOverachiever(flag) {
    this.toggleBodyScroll("overachiever-modal", flag);
  }

  toggleBodyScroll(elId, flag) {
    const $el = document.getElementById(elId);
    if (!$el) {
      return;
    }

    if (flag) {
      disableBodyScroll($el);
    } else {
      enableBodyScroll($el);
    }
  }

  desiredTimeframeChanged(value) {
    this.household.desiredTimeframe = value;
    this.desiredTimeframe = value;
    this.validateForm();
  }

  loadFiltersData() {
    this.desiredTimeframe = this.household.desiredTimeframe;
  }

  setActiveFilter(filterName) {
    this.$nextTick(() => {
      this.EventBus.$emit.setActiveFilter(filterName);
    });
  }

  locationChanged(selectedOutcodes, country) {
    this.$store.commit("household/setLastSelectedOutcodes", selectedOutcodes);
    this.$store.commit("household/setHouseRegion", country);
  }

  fetchProperties() {
    this.calculateHousePrice();
    this.$store.commit("household/clearPropertyId");
    this.$store.dispatch("property/fetchProperties", this.selectedOutcodes);
    window.setTimeout(() => {
      this.firstVisit = false;
    }, 500);
  }

  calculateHousePrice() {
    this.$store.dispatch("targetPlan/calculateHousePrice", this.household);
  }

  get showSearchHint() {
    return this.noProperties && this.firstVisit;
  }

  get displayAffordability() {
    return !this.firstVisit && this.targetPlan.affordableHomePrice > 0;
  }

  get resultType() {
    if (this.targetPlan.affordableHomePrice === 0) {
      return "no-affordability";
    } else {
      return this.propertyResultType;
    }
  }
}
</script>
