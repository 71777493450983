const min8characters = (value) => {
  return value.length >= 8;
};

const minOneNumber = (value) => {
  return /\d{1}/.test(value);
};

const minOneSpecial = (value) => {
  return /[*@$!#%&()^~{}]+/.test(value);
};

const isPasswordStrong = (value) => {
  return min8characters(value) && minOneNumber(value) && minOneSpecial(value);
};

export { min8characters, minOneNumber, minOneSpecial, isPasswordStrong };
