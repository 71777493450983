
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "app-tooltip",
})
export default class Tooltip extends Vue {
  @Prop({ type: String, default: "top" })
  position!: string;

  @Prop({ type: String, required: true })
  labelKey!: string;

  get content() {
    return this.$t(this.labelKey);
  }
}
