import { periodInWords, dateInMonths } from "@/modules/utils/date_helpers";
import { currencyFormatter } from "@/modules/utils/formatter";

const DEFAULT_VALUES = {
  affordableHomePrice: 0,
  estimatedTargetMoveinDate: dateInMonths(12),
  increaseTimeEstimatedMoveInDate: dateInMonths(12),
  deposit: 0,
  legalFees: 0,
  stampDuty: 0,
  mortgageFees: 0,
  monthlySavings: 0,
  savingPeriod: 0,
  goalAlreadyMet: false,
  homeGoal: 0,
  depositPercentage: 0,
  surveyorCosts: 0,
};

export default class {
  constructor(params = {}) {
    this.affordableHomePrice =
      params.affordableHomePrice || DEFAULT_VALUES.affordableHomePrice;
    this.estimatedTargetMoveinDate =
      params.estimatedTargetMoveinDate ||
      DEFAULT_VALUES.estimatedTargetMoveinDate;
    this.increaseTimeEstimatedMoveInDate =
      params.increaseTimeEstimatedMoveInDate ||
      DEFAULT_VALUES.increaseTimeEstimatedMoveInDate;
    this.monthlySavings =
      params.monthlySavings || DEFAULT_VALUES.monthlySavings;
    this.savingPeriod = params.savingPeriod || DEFAULT_VALUES.savingPeriod;
    this.deposit = params.deposit || DEFAULT_VALUES.deposit;
    this.legalFees = params.legalFees || DEFAULT_VALUES.legalFees;
    this.stampDuty = params.stampDuty || DEFAULT_VALUES.stampDuty;
    this.mortgageFees = params.mortgageFees || DEFAULT_VALUES.mortgageFees;
    this.goalAlreadyMet =
      params.goalAlreadyMet || DEFAULT_VALUES.goalAlreadyMet;
    this.homeGoal = params.homeGoal || DEFAULT_VALUES.homeGoal;
    this.depositPercentage =
      params.depositPercentage || DEFAULT_VALUES.depositPercentage;
    this.surveyorCosts = params.surveyorCosts || DEFAULT_VALUES.surveyorCosts;
  }

  get formattedAffordableHomePrice() {
    return currencyFormatter(this.affordableHomePrice, { round: 1000 });
  }

  get formattedHomeGoal() {
    return currencyFormatter(this.homeGoal);
  }

  get savingPeriodDescription() {
    return periodInWords(this.savingPeriod);
  }

  get chartData() {
    const otherFees = this.mortgageFees + this.surveyorCosts;
    return [this.deposit, this.stampDuty, this.legalFees, otherFees];
  }
}
