import { Person } from "../types";

const createPerson = ({
  currentSavings = 0,
  monthlySavings = 0,
  yearlyIncome = 0,
  additionalMoney = 0,
} = {}): Person => ({
  currentSavings,
  monthlySavings,
  yearlyIncome,
  additionalMoney,
});

export { createPerson };
