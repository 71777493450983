<template functional>
  <div class="multi-select-entry">
    <svg class="multi-select-entry-check" width="18" height="18">
      <use xlink:href="./checkbox.svg#checked" v-if="props.checked"></use>
      <use xlink:href="./checkbox.svg#unchecked" v-else></use>
    </svg>
    <span>{{ props.label }}</span>
  </div>
</template>

<script>
export default {
  props: ["label", "checked"],
};
</script>

<style lang="scss">
.multi-select-entry {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.multi-select-entry-check {
  display: inline-block;
  margin-right: 10px;
}
</style>
