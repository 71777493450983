<template>
  <div>
    <span class="ctaButton" @click="openFilters">{{
      $t("pages.dream_home.show_more_filters")
    }}</span>

    <full-page pageId="dream-home-filters" :pageTitle="$t('filters')">
      <div slot="body">
        <app-accordion>
          <a-collapse-panel
            key="totalSavings"
            :header="$t('pages.dream_home.filters.total_monthly_savings.label')"
          >
            <a-form-model-item
              :label="
                $t(
                  'pages.dream_home.filters.total_monthly_savings.owner_savings_label',
                )
              "
            >
              <a-input-number
                v-model="ownerSavings"
                :min="0"
                :formatter="formatCurrency"
                :parser="parseCurrency"
                @change="ownerSavingsChanged"
              />
            </a-form-model-item>

            <a-form-model-item
              :label="
                $t(
                  'pages.dream_home.filters.total_monthly_savings.partner_savings_label',
                )
              "
              v-if="householdWithPartner"
            >
              <a-input-number
                v-model="partnerSavings"
                :min="0"
                :formatter="formatCurrency"
                :parser="parseCurrency"
                @change="partnerSavingsChanged"
              />
            </a-form-model-item>
          </a-collapse-panel>

          <a-collapse-panel
            key="desiredTimeframe"
            :header="$t('desired_timeframe.desired_timeframe')"
          >
            <desired-timeframe
              @search-option-changed="updateDesiredTimeframe"
              :value="desiredTimeframe"
            />
          </a-collapse-panel>

          <a-collapse-panel
            key="propertyType"
            :header="
              $t('pages.dream_home.filters.property_type.property_type_label')
            "
          >
            <multiple-select
              :options="propertyTypeOptions"
              placeholderKey="pages.dream_home.filters.property_type.placeholder"
              @multiple-select-value-changed="propertyTypeChanged"
              :value="propertyType"
            />
          </a-collapse-panel>

          <a-collapse-panel
            key="bedroomAmount"
            :header="$t('pages.dream_home.filters.bedrooms.bedrooms_label')"
          >
            <multiple-select
              :options="bedroomsOptions"
              placeholderKey="pages.dream_home.filters.bedrooms.placeholder"
              @multiple-select-value-changed="bedroomAmountChanged"
              :value="bedroomsAmount"
            />
          </a-collapse-panel>
        </app-accordion>
      </div>
      <div v-if="showApply" slot="footer">
        <app-button
          class="applyButton"
          variant="primary"
          @button-click="applyChanges"
        >
          {{ $t("apply") }}
        </app-button>
      </div>
    </full-page>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";

import FormMixin from "@/components/form/mixins/";
import AppButton from "@/components/button/app-button.vue";
import FullPage from "@/components/full-page/index.vue";
import AppAccordion from "@/components/app-accordion/index.vue";
import DesiredTimeframe from "@/components/desired-timeframe/index.vue";
import MultipleSelect from "@/components/multiple-select/index.vue";
import { DEFAULT_BEDROOMS_AMOUNT } from "@/modules/household/config";
import { bedroomsAmountOptions } from "./helpers/bedrooms-amount-options";
import { propertyTypeOptions } from "./helpers/property-type-options";

@Component({
  name: "filters-compact",
  inject: ["EventBus"],
  mixins: [FormMixin],
  components: {
    FullPage,
    DesiredTimeframe,
    MultipleSelect,
    AppAccordion,
    AppButton,
  },
  computed: {
    ...mapGetters({
      household: "household/household",
      householdWithPartner: "household/withPartner",
      totalMonthlySavings: "household/totalMonthlySavings",
    }),
  },
})
export default class extends Vue {
  // TODO: move default values to constants
  activeFilter = "totalSavings";

  ownerSavings = 0;
  partnerSavings = 0;
  desiredTimeframe = 0;
  bedroomsAmount = DEFAULT_BEDROOMS_AMOUNT;
  propertyType = ["flat", "house"];

  filters = {
    ownerSavings: 0,
    partnerSavings: 0,
    desiredTimeframe: 0,
    bedroomsAmount: DEFAULT_BEDROOMS_AMOUNT,
    propertyType: ["flat", "house"],
  };

  propertyTypeOptions = propertyTypeOptions;
  bedroomsOptions = bedroomsAmountOptions;

  get showApply() {
    const householdData = this.householdData;

    const ownerChanges =
      (householdData.ownerSavings !== this.filters.ownerSavings &&
        Number.isInteger(this.ownerSavings)) ||
      householdData.desiredTimeframe != this.filters.desiredTimeframe ||
      this.bedroomAmountDiffers() ||
      this.propertyTypeDiffers();

    if (this.household.withPartner) {
      return (
        ownerChanges ||
        (householdData.partnerSavings !== this.filters.partnerSavings &&
          Number.isInteger(this.partnerSavings))
      );
    } else {
      return ownerChanges;
    }
  }

  get householdData() {
    const household = { ...this.household };

    return {
      ownerSavings: household.owner.monthlySavings,
      partnerSavings: household.partner.monthlySavings,
      desiredTimeframe: household.desiredTimeframe,
      bedroomsAmount: household.bedroomsAmount,
      propertyType: household.propertyType,
    };
  }

  mounted() {
    this.activeFilterListener();
  }

  openFilters() {
    this.loadData();
    this.EventBus.$emit.openFullPage("dream-home-filters");
  }

  setActiveFilter(filterName) {
    this.activeFilter = filterName;
  }

  applyChanges() {
    const household = { ...this.household };

    household.owner.monthlySavings = this.filters.ownerSavings;
    household.desiredTimeframe = this.filters.desiredTimeframe;
    household.propertyType = this.filters.propertyType;
    household.bedroomsAmount = this.filters.bedroomsAmount;

    if (household.withPartner) {
      household.partner.monthlySavings = this.filters.partnerSavings;
    }

    this.EventBus.$emit.filtersChanged(household);
  }

  updateDesiredTimeframe(value) {
    this.desiredTimeframe = value;
    this.filters.desiredTimeframe = value;
  }

  bedroomAmountChanged(value) {
    this.$nextTick(() => {
      this.bedroomsAmount = [...value];
      this.filters.bedroomsAmount = [...value];
    });
  }

  propertyTypeChanged(value) {
    this.$nextTick(() => {
      this.propertyType = value;
      this.filters.propertyType = [...value];
    });
  }

  ownerSavingsChanged(value) {
    if (Number.isInteger(value)) {
      this.filters.ownerSavings = value;
    }
  }

  partnerSavingsChanged(value) {
    if (Number.isInteger(value)) {
      this.filters.partnerSavings = value;
    }
  }

  bedroomAmountDiffers() {
    return (
      this.householdData.bedroomsAmount.sort().join() !==
        this.filters.bedroomsAmount.sort().join() &&
      this.filters.bedroomsAmount.length
    );
  }

  propertyTypeDiffers() {
    return (
      this.householdData.propertyType.sort().join() !==
        this.filters.propertyType.sort().join() &&
      this.filters.propertyType.length
    );
  }

  loadData() {
    const household = this.householdData;

    this.ownerSavings = household.ownerSavings;
    this.partnerSavings = household.partnerSavings;
    this.desiredTimeframe = household.desiredTimeframe;
    this.propertyType = household.propertyType;
    this.bedroomsAmount = household.bedroomsAmount;

    this.filters.ownerSavings = household.ownerSavings;
    this.filters.partnerSavings = household.partnerSavings;
    this.filters.desiredTimeframe = household.desiredTimeframe;
    this.filters.bedroomsAmount = household.bedroomsAmount;
    this.filters.propertyType = household.propertyType;
  }

  activeFilterListener() {
    this.EventBus.$on.setActiveFilter(() => {
      this.setActiveFilter("totalSavings");
      this.openFilters();
      this.loadData();
    });
  }
}
</script>

<style lang="scss" scoped>
.ctaButton {
  display: inline-block;
  margin-top: 8px;
  color: $brand-color-primary-coral;
  cursor: pointer;
  font-size: $font-size-mobile-2;
  line-height: $line-height-1;
  font-weight: $font-weight-semi-bold;
}

.applyButton {
  width: 100%;
}

.ant-select {
  width: 100%;
}
</style>
