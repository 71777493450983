import { DEFAULT_BEDROOMS_AMOUNT } from "@/modules/household/config";

export default {
  withPartner: false,
  savingJourneyOption: 2,
  desiredTimeframe: 12 * 6,
  houseRegion: "England",
  bedroomsAmount: DEFAULT_BEDROOMS_AMOUNT,
  propertyType: ["flat", "house"],
  owner: {
    currentSavings: 20000,
    monthlySavings: 400,
    yearlyIncome: 70000,
    additionalMoney: 10000,
  },
  partner: {
    currentSavings: 2000,
    monthlySavings: 150,
    yearlyIncome: 15000,
    additionalMoney: 2500,
  },
  propertyId: null,
};
