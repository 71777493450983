
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

type OptionValue = string | number | null;
interface Option {
  value: OptionValue;
  label: string;
}

@Component({
  name: "app-search",
})
export default class AppSearch extends Vue {
  @Prop({ default: "select", type: String })
  placeholderKey!: string;

  @Prop() options!: Array<Option>;
  @Prop({ required: false })
  defaultValue!: OptionValue;
  @Prop({ required: true })
  value!: OptionValue;

  selectIconPath = {
    selectOpen: "img/search-icon.svg",
    selectClosed: "img/arrow-up-icon.svg",
  };

  selectedValue = this.value;
  defaultIcon = this.selectIconPath.selectClosed;

  open = false;

  @Watch("value")
  valueUpdated() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.selectedValue = this.value as any;
  }

  get placeholder() {
    return this.$t(this.placeholderKey);
  }

  get iconPath() {
    return this.open
      ? this.selectIconPath.selectOpen
      : this.selectIconPath.selectClosed;
  }

  onDropdownVisivilityChange(open = false) {
    this.open = open;
  }

  onChange(value) {
    this.selectedValue = value;
    this.$emit("search-option-changed", value);
  }
}
