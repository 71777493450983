import Household from "./model";
import developmentData from "../data/fixture";

const loadDevelopmentData = ["development", "test"].includes(
  process.env.NODE_ENV,
);

const initialUiState = {
  lastSearchedLocation: "",
  lastSearchedCountry: "England",
  lastSelectedOutcodes: [],
  selectedPropertyReadAt: null,
};

const initialSelectedProperty = {
  dreamArea: "–",
  typeOfHome: "–",
  formattedPrice: "–",
  price: 0,
};

const initialState: Household = loadDevelopmentData
  ? { data: new Household(developmentData), ui: { ...initialUiState } }
  : {
      data: new Household(),
      ui: { ...initialUiState },
    };

export default {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    update(state, payload) {
      state.data.withPartner = payload.withPartner;
      state.data.savingJourneyOption = payload.savingJourneyOption;
      state.data.desiredTimeframe = payload.desiredTimeframe;
      state.data.bedroomsAmount = payload.bedroomsAmount;
      state.data.propertyType = payload.propertyType;
      state.data.owner = payload.owner;
      state.data.partner = payload.partner;
      state.data.propertyId = payload.propertyId;
    },
    setPropertyId(state, payload) {
      state.data.propertyId = payload;
    },
    clearPropertyId(state) {
      state.data.propertyId = null;
    },
    setHouseRegion(state, payload) {
      state.data.houseRegion = payload;
    },
    setLastSearchedLocation(state, payload) {
      state.ui.lastSearchedLocation = payload;
    },
    setLastSearchedCountry(state, payload) {
      state.ui.lastSearchedCountry = payload;
    },
    setLastSelectedOutcodes(state, payload) {
      state.ui.lastSelectedOutcodes = payload;
    },
    updateSelectedPropertyReadAt(state) {
      state.ui.selectedPropertyReadAt = new Date().getTime();
    },
  },
  getters: {
    household: (state) => state.data,
    withPartner: (state) => state.data.withPartner,
    totalMonthlySavings: (state) => {
      const { owner, partner, withPartner } = state.data;

      return withPartner
        ? owner.monthlySavings + partner.monthlySavings
        : owner.monthlySavings;
    },
    selectedPropertyId: (state) => state.data.propertyId,
    selectedProperty: (state, getters, rootState, rootGetters) => {
      if (!getters.selectedPropertyId) {
        return initialSelectedProperty;
      }

      const {
        location,
        propertyTypeDescription,
        formattedPrice,
        price,
        affordability,
      } = rootGetters["property/propertyById"](getters.selectedPropertyId);
      return {
        dreamArea: location,
        typeOfHome: propertyTypeDescription,
        formattedPrice,
        price,
        affordability,
      };
    },
    selectedPropertyReadAt: (state) => state.ui.selectedPropertyReadAt,
    lastSearchedLocation: (state) => state.ui.lastSearchedLocation,
    lastSearchedCountry: (state) => state.ui.lastSearchedCountry,
    lastSelectedOutcodes: (state) => state.ui.lastSelectedOutcodes,
  },
};
