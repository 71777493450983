/* eslint-disable no-irregular-whitespace */
type RoundValue = 100 | 1000 | false;

const CURRENCY_SYMBOL = "£";

const valueWithCurrency = (value) => {
  const _value = value.toString();
  return `${CURRENCY_SYMBOL}${_value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

const spaceCurrencySymbol = (value) => {
  return value.replace(/£\s?/g, "£ ");
};

const kFormatter = (num) => {
  const _value = Math.sign(num) * +(Math.abs(num) / 1000).toFixed(1) + "k";
  return `${CURRENCY_SYMBOL}${_value}`;
};

const roundTo1000 = (num) => {
  const r = num % 1000;
  const bv = num - r;
  let diff = 0;

  if (r < 500) {
    diff = 0;
  } else {
    diff = 1000;
  }

  return bv + diff;
};

const roundTo100 = (num) => {
  const r = num % 100;
  const bv = num - r;
  let diff = 0;

  if (r < 50) {
    diff = 0;
  } else {
    diff = 100;
  }
  return bv + diff;
};

const _currencyFormatter = (value, round: RoundValue = 100) => {
  if (round === false) {
    return valueWithCurrency(value);
  }

  const _roundTo: RoundValue = [100, 1000].includes(round) ? round : 100;

  if (value < 1000) {
    return valueWithCurrency(Math.ceil(value));
  }

  if (_roundTo === 1000) {
    return valueWithCurrency(roundTo1000(value));
  } else {
    return valueWithCurrency(roundTo100(value));
  }
};

const currencyFormatter = (
  value,
  {
    round = 100,
    short = false,
    symbolWithSpace = false,
  }: { round?: RoundValue; short?: boolean; symbolWithSpace?: boolean } = {},
) => {
  let _value;

  if (short && Math.abs(value) > 999) {
    _value = kFormatter(value);
  } else {
    _value = _currencyFormatter(value, round);
  }

  if (symbolWithSpace) {
    _value = spaceCurrencySymbol(_value);
  }
  return _value;
};

export { currencyFormatter };
