
import {Vue, Component} from "vue-property-decorator";

import AppButton from "@/components/button/app-button.vue";
import PasswordStrength from "./password-strength.vue";

import validationRules from "./validation-rules";

import api from "@habstash/habstash-frontend-api/lib/api/index";

@Component({
  name: "registration-form",
  components: {AppButton, PasswordStrength},
})
export default class RegistrationForm extends Vue {
  form = {
    username: "",
    email: "",
    password: "",
    updatesAgreement: false,
  };
  policyChecked = false;
  isFormValid = false;
  rules = validationRules;

  onValidated(_prop, isValid) {
    this.isFormValid = isValid;
  }

  togglePolicy() {
    this.policyChecked = !this.policyChecked;
  }

  toggleUpdatesAgreement() {
    this.form.updatesAgreement = !this.form.updatesAgreement;
  }

  get canBeSubmitted() {
    // if (process.env.NODE_ENV === "development") {
    //   return true;
    // }
    return this.isFormValid && this.policyChecked;
  }

  submit() {
    const form = this.form;

    const payload = {
      username: form.username,
      email: form.email,
      password: form.password,
    };
    this.$store.dispatch("targetPlan/register", payload);
    if (form.updatesAgreement) {
      api.newsletter.addEmailToNewsletter(form.email)
    }
  }

  data() {
    return {
      termsOfUseUrl: process.env.VUE_APP_TERMS_OF_USE_URL,
      privacyPolicyUrl: process.env.VUE_APP_PRIVACY_POLICY_URL,
    };
  }
}
