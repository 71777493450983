import Vue from "vue";
import Vuex from "vuex";
import system from "./system";
import mobile from "./mobile";
import household from "./household/household";
import targetPlan from "../target-plan/store";
import property from "../property/store";
import housing from "../housing/store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { system, mobile, household, targetPlan, property, housing },
});
