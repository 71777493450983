import i18n from "../../../../core/i18n";
import { createPerson } from "./factories/index";
import { DEFAULT_BEDROOMS_AMOUNT } from "@/modules/household/config";

const propertyToEnum = {
  flat: 2,
  house: 1,
};

const DEFAULT_VALUES = {
  withPartner: false,
  savingJourneyOption: 0,
  desiredTimeframe: null,
  houseRegion: "England",
  bedroomsAmount: DEFAULT_BEDROOMS_AMOUNT,
  propertyType: ["flat", "house"],
  owner: createPerson(),
  partner: createPerson(),
  propertyId: null,
};

const SAVING_JOURNEY_OPTIONS_KEY = "pages.home_status.saving_journey_options";
const SAVING_JOURNEY_OPTIONS = new Map([
  [0, ["not_selected", "not_selected"]],
  [1, ["thinking", "thinking"]],
  [2, ["saving_for_first_home", "saving_for_first_home_with_partner"]],
  [3, ["switching_home", "switching_home_with_partner"]],
  [4, ["additional_home", "additional_home_with_partner"]],
]);

const FIRST_TIME_BUYER_STATE_MAP = new Map([
  [1, "thinking"],
  [2, "firstHome"],
  [3, "switchingHouse"],
  [4, "additionalHouse"],
]);

export default class {
  constructor(params = {}) {
    this.withPartner = params.withPartner || DEFAULT_VALUES.withPartner;
    this.savingJourneyOption =
      params.savingJourneyOption || DEFAULT_VALUES.savingJourneyOption;
    this.desiredTimeframe =
      params.desiredTimeframe || DEFAULT_VALUES.desiredTimeframe;
    this.houseRegion = params.houseRegion || DEFAULT_VALUES.houseRegion;
    this.bedroomsAmount =
      params.bedroomsAmount || DEFAULT_VALUES.bedroomsAmount;
    this.propertyType = params.propertyType || DEFAULT_VALUES.propertyType;
    this.owner = params.owner || DEFAULT_VALUES.owner;
    this.partner = params.partner || DEFAULT_VALUES.partner;
    this.propertyId = params.propertyId || DEFAULT_VALUES.propertyId;
  }

  get savingJourneyStatus() {
    return this.savingJourneyOptionLabel(this.savingJourneyOption);
  }

  get additionalHouse() {
    return this.savingJourneyOption === 4;
  }

  get firstTimeBuyer() {
    return [1, 2].includes(this.savingJourneyOption);
  }

  get firstTimeBuyerState() {
    if (this.savingJourneyOption === 0) {
      return "";
    }

    return FIRST_TIME_BUYER_STATE_MAP.get(this.savingJourneyOption);
  }

  get consideringHomeLabel() {
    return this.savingJourneyOptionLabel(1);
  }

  get savingForFirstHomeLabel() {
    return this.savingJourneyOptionLabel(2);
  }

  get switchingHomeLabel() {
    return this.savingJourneyOptionLabel(3);
  }

  get additionalHomeLabel() {
    return this.savingJourneyOptionLabel(4);
  }

  get propertyTypeEnum() {
    return this.propertyType.map((p) => propertyToEnum[p]);
  }

  get calculatedSavings() {
    return this.withPartner
      ? this.owner.monthlySavings + this.partner.monthlySavings
      : this.owner.monthlySavings;
  }

  savingJourneyOptionLabel(status = 1) {
    const labelKey =
      SAVING_JOURNEY_OPTIONS.get(status)[Number(this.withPartner)];
    return i18n.tc(`${SAVING_JOURNEY_OPTIONS_KEY}.${labelKey}`);
  }
}
