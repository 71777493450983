import buildPayload from "../helpers/build-payload";
import api from "@habstash/habstash-frontend-api/lib/api/index";

const initialState = {};

export default {
  namespaced: true,
  state: { ...initialState },
  actions: {
    async ["create"]({ rootGetters }, { userUid }) {
      const household = rootGetters["household/household"];
      const stretchPlan = rootGetters["targetPlan/stretchPlan"];
      const targetPlan = rootGetters["targetPlan/targetPlan"];
      const selectedPropertyId = rootGetters["household/selectedPropertyId"];
      const property = rootGetters["property/propertyById"](selectedPropertyId);

      const payload = buildPayload({
        household,
        targetPlan,
        property,
        authData: {
          userUid,
        },
        stretchPlan,
      });
      try {
        const { data } = await api.housing.create(payload);
        if (process.env.NODE_ENV === "development") {
          console.log(data);
        }
      } catch ({ message }) {
        console.log(message);
      }
    },
  },
  mutations: {},
  getters: {},
};
