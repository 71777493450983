/* eslint-disable @typescript-eslint/camelcase */

const buildHouseholdPayload = (household, targetPlan, stretchPlan) => {

  const increaseTime = stretchPlan.stretchedBy === "increaseSaving";
  const owner = {
    expectedMoney: household.owner.additionalMoney,
    hasPartner: household.withPartner,
    houseRegion: household.houseRegion,
    monthlyContribution: household.owner.monthlySavings,
    savings: household.owner.currentSavings,
    yearlyEarnings: household.owner.yearlyIncome,
    thinkingFirstTimeBuyer: household.savingJourneyOption === 1,
    firstTimeBuyer: household.savingJourneyOption === 2,
    switchingHome: household.savingJourneyOption === 3,
    additionalHouse: household.savingJourneyOption === 4,
    targetMoveInMonths: increaseTime
      ? household.desiredTimeframe
      : stretchPlan.increaseTime,
    moveInMonths: targetPlan.savingPeriod,
  };

  const partner = household.withPartner
    ? Object.assign(
        {},
        {
          partnerExpectedMoney: household.partner.additionalMoney,
          partnerMonthlyContribution: household.partner.monthlySavings,
          partnerSavings: household.partner.currentSavings,
          partnerYearlyEarnings: household.partner.yearlyIncome,
        },
      )
    : {};

  return {
    ...owner,
    ...partner,
  };
};

const buildTargetPlanPayload = (targetPlan, stretchPlan) => {
  const increaseTime = stretchPlan.stretchedBy === "increaseSaving";
  return {
    affordableHomePrice: targetPlan.affordableHomePrice,
    depositGoal: targetPlan.deposit,
    targetMoveinDate: increaseTime
      ? targetPlan.estimatedTargetMoveinDate
      : targetPlan.increaseTimeEstimatedMoveInDate,
  };
};

const buildDreamAreaJson = (property, savingJourneyOption = 0) => {
  const dreamArea = {
    bedrooms: property.bedroomsAmount,
    postCode: property.location,
    price: property.price,
    savingJourneyOption: savingJourneyOption,
  };

  return {
    dreamAreaJson: JSON.stringify({ ...dreamArea, ...property }),
  };
};

const notImplementedYet = {
  lisaContribution: 0,
  lisaEnabled: false,
};

const buildPayload = ({
  household,
  targetPlan,
  property,
  authData,
  stretchPlan,
}) => {
  return Object.assign(
    {},
    buildHouseholdPayload(household, targetPlan, stretchPlan),
    buildTargetPlanPayload(targetPlan, stretchPlan),
    buildDreamAreaJson(property, household.savingJourneyOption),
    {
      userUid: authData.userUid,
    },
    {
      ...notImplementedYet,
    },
  );
};

export default buildPayload;
